import { makeStyles } from "tss-react/mui";

const useLabelStyles = makeStyles()((theme) => ({
  label: {
    margin: "10px",
    color: theme.palette.grey.A700,
    display: "flex",
    alignItems: "center",
  },
}));

export default useLabelStyles;
