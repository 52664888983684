import { makeStyles } from "tss-react/mui";

const useLoginStyles = makeStyles()((theme) => ({
  icon: {
    color: "rgb(255,255,255)",
  },
  errorText: {
    textAlign: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  spacing: {
    paddingBottom: theme.spacing(3),
  },
  spacing05: {
    paddingBottom: theme.spacing(1.5),
  },
  password: {
    color: "rgb(255,255,255)",
    marginTop: theme.spacing(3),
    fontSize: "0.9em",
  },
}));
export default useLoginStyles;
