import { makeStyles } from "tss-react/mui";

const useAuthStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    flexWrap: "nowrap",
    WebkitFlexWrap: "nowrap",
  },
  root: {
    width: "80%",
    margin: "5% auto 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  language: {
    paddingBottom: theme.spacing(5),
    textAlign: "right",
  },
  bottom: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  loginDiv: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  logo: {
    paddingBottom: theme.spacing(10),
  },
}));

export default useAuthStyles;
