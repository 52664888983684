import { makeStyles } from "tss-react/mui";

const useErrorPageStyles = makeStyles()((theme) => ({
  root: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    height: "100%",
    gap: "10px",
  },
  icon: {
    color: theme.palette.error.main,
    fontSize: "100px",
  },
}));

export default useErrorPageStyles;
