import { useState, useEffect } from "react";
import { getAlternativeAddresses } from "../modules/api/salesPurchases";
import { userClientDb } from "../modules/auth/selectors/userSelector";
import { useSelector } from "react-redux";
import axios, { Canceler } from "axios";

function useAddressFetching(
  fil: string,
  pageNumber: number,
  clientCode: string,
  module: string
) {
  const clientDb = useSelector(userClientDb);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [addresses, setAddresses] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setAddresses([]);
  }, [fil, clientCode]);

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    let cancel: Canceler;

    const query = {
      clientDbId: clientDb ? clientDb.id : 0,
      client: clientCode,
      pageNumber: pageNumber,
      fil: fil,
      module: module,
    };

    const config = {
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    };

    getAlternativeAddresses(query, config)
      .then(({ data }) => {
        setAddresses((prevClients: any[]) => {
          return [...prevClients, ...data];
        });
        setLoading(false);
        // setHasMore(data.length > 0);
        setHasMore(data.length > 0 && data[0].pageNumber > pageNumber);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setError(err);
      });

    return () => cancel();
  }, [fil, pageNumber, clientDb, clientCode]);

  return { loading, error, addresses, hasMore };
}

export default useAddressFetching;
