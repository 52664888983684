import React, { useCallback, useState } from "react";
import OutlinedTextField from "./OutlinedTextField";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { isNumber } from "../../utils/validate";
import {
  uiLanguage,
  userAutoMode,
  userSemiAutoMode,
  userLotUsage,
  userObjectUsage,
  userCopyLot,
  userCopyObject,
  refreshData,
  userId,
  userFocusAfterBarcode,
  userExtraFieldsUsage,
  extraFieldsArray,
  useExtraField1,
  useExtraField2,
  useExtraField3,
  useExtraField4,
  useExtraField5,
  userCamera,
  userDepartmentId,
} from "../../modules/auth/selectors/userSelector";
import { useSelector } from "react-redux";
import {
  getProductInfoByBarcode,
  getProductInfoCustom,
} from "../../modules/api/productInfo";
import { barcodeScanner } from "./formUtils";
import WhenBarcodeChanges from "./WhenBarcodeChanges";
import { Label, Button, Alert, Drawer, Camera2 } from "../index";
import OutlinedAutocomplete from "./OutlinedAutocomplete";
import {
  ArrowForward,
  InfoOutlined,
  EditOutlined,
  IsoOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useScannerFormStyles } from "./css";
import { getErrorMessage, getWarningMessage } from "./formUtils";
import { useAuthorizationError, useLoader, useLocalStorage } from "../../hooks";
import * as modules from "../../utils/modules";
import {
  getUserValue,
  otherUsersSettings,
} from "../../modules/settings/components/utils";
import { ExtraFieldsForm } from "../form";
import { TextField } from "../index";

interface IScannerForm {
  module: string;
  operationCode?: string;
  operationType?: string;
  userDb: number;
  updateData: (data: any) => void;
  onSubmit: (params: {
    [name: string]: any;
  }) => Promise<{ data: any; warnings: any }>;
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

function ScannerForm(props: IScannerForm) {
  const {
    module,
    operationCode,
    operationType,
    userDb,
    updateData,
    onSubmit: userSubmitFunc,
  } = props;

  const moduleParams = useSelector(extraFieldsArray);
  const [logoutUser] = useAuthorizationError();
  const { classes, cx } = useScannerFormStyles();
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const autoMode = useSelector(userAutoMode);
  const semiAutoMode = useSelector(userSemiAutoMode);
  const useLot = useSelector(userLotUsage);
  const useObject = useSelector(userObjectUsage);
  const useExtra = useSelector(userExtraFieldsUsage);
  const extra1 = useSelector(useExtraField1);
  const extra2 = useSelector(useExtraField2);
  const extra3 = useSelector(useExtraField3);
  const extra4 = useSelector(useExtraField4);
  const extra5 = useSelector(useExtraField5);
  const departmentId = useSelector(userDepartmentId);
  const focusAfterBarcode = useSelector(userFocusAfterBarcode);
  const barcodeRef = React.useRef<HTMLInputElement>();
  const quantityRef = React.useRef<HTMLInputElement>();
  const lotRef = React.useRef<HTMLInputElement>();
  const objectRef = React.useRef<HTMLInputElement>();
  const buttonRef = React.useRef<HTMLButtonElement>();
  const [extraInfo, setExtraInfo] = useState<{ [name: string]: any }>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [, setLoader] = useLoader();
  const [isProductCustom] = useLocalStorage("productCustomProc");
  const user = useSelector(userId);
  const [savedObject, setSavedObject] = useLocalStorage("savedObject");
  const [savedLot, setSavedLot] = useLocalStorage("savedLot");
  const savedLotValue = savedLot ? getUserValue(savedLot, user) : "";
  const savedObjectValue = savedObject ? getUserValue(savedObject, user) : "";
  const copyObject = useSelector(userCopyObject);
  const copyLot = useSelector(userCopyLot);
  const refresh = useSelector(refreshData);
  const [objectsList, setObjectsList] =
    useState<{ label: string; value: any }[]>();
  const [showExtra, setShowExtra] = useState<{
    show: boolean;
    data: { [key: string]: any };
  }>({ show: false, data: {} });
  const camera = useSelector(userCamera);

  const [fieldsParams, setFieldsParams] = useState<{ [name: string]: any }[]>();
  let submitFunction: any;
  let formResetFunction: any;
  let scannerForm: any;
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    error: false,
    status: 0,
  });
  const [scannedData, setScannedData] = useState({
    clientDbId: userDb,
    locale: locale,
    module: module,
    operationType: operationType || "",
    operationCode: operationCode || "",
    barcode: "",
    quantityApp: 0,
    lot: "",
    objectCode: "",
  });

  const initialValues = {
    barcode: "",
    quantityApp: 1,
    objectCode: copyObject && useObject ? savedObjectValue : "",
    lot: copyLot && useLot ? savedLotValue : "",
  };

  const scan = (params: any) => {
    setLoader(true);

    userSubmitFunc(params)
      .then(({ data, warnings }) => {
        setLoader(false);
        //Case when data has a warning message
        if (warnings && warnings.length > 0) {
          const warningMessage = getWarningMessage(warnings);

          setErrorMessage({
            message: warningMessage,
            error: false,
            status: 0,
          });

          setShowAlert(true);
          return;
        }

        if (data && data.length > 0) {
          updateData(data);
          if (barcodeRef.current) barcodeRef.current.focus();
        }
      })
      .catch((err: any) => {
        setLoader(false);
        setShowAlert(true);
        const message = getErrorMessage(err);

        setErrorMessage({
          message: message.message,
          error: true,
          status: message.status,
        });
      })
      .finally(() => {
        let newInitialValues = { ...initialValues };

        if (copyObject) {
          const others = otherUsersSettings(savedObject, user);
          setSavedObject([
            ...others,
            { userId: user, value: params.objectCode },
          ]);

          newInitialValues = {
            ...newInitialValues,
            objectCode: params.objectCode,
          };
        }
        if (copyLot) {
          const others = otherUsersSettings(savedLot, user);
          setSavedLot([...others, { userId: user, value: params.lot }]);

          newInitialValues = { ...newInitialValues, lot: params.lot };
        }

        formResetFunction(newInitialValues);
        setShowExtra({
          show: false,
          data: {
            extraInfo1: undefined,
            extraInfo2: undefined,
            extraInfo3: undefined,
            extraInfo4: undefined,
            extraInfo5: undefined,
            note: undefined,
          },
        });
      });
  };

  const onSubmit = (values: any) => {
    const barcode = barcodeScanner(values.barcode);
    const quantity =
      values.quantityApp && typeof values.quantityApp === "string"
        ? values.quantityApp.replace(",", ".")
        : values.quantityApp;

    const params = {
      ...scannedData,
      clientDbId: userDb,
      module: module,
      operationType: operationType || "",
      operationCode: operationCode || "",
      barcode: barcode.barcode,
      quantityApp: quantity,
      lot: values.lot || "",
      objectCode: values.objectCode || "",
      ...showExtra.data,
      scannedWithCamera: camera,
      departmentId: departmentId,
    };

    setScannedData(params);
    scan(params);
  };

  const handleCancelAlert = () => {
    logoutUser(errorMessage.status);

    if (errorMessage.status !== 401) {
      setShowAlert(false);
    }
  };

  const handleConfirmAlert = () => {
    const params = { ...scannedData, ignoreWarnings: 1 };
    setShowAlert(false);
    scan(params);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement> & {
      target: HTMLInputElement;
    }
  ) => {
    if (event.key === "Enter" && !autoMode) {
      const fieldName =
        event.target && event.target.name ? event.target.name : "";

      switch (fieldName) {
        case "barcode":
          if (focusAfterBarcode === "object" && objectRef.current) {
            objectRef.current.focus();
          } else if (focusAfterBarcode === "lot" && lotRef.current) {
            lotRef.current.focus();
          } else if (quantityRef.current) quantityRef.current.focus();
          break;

        case "quantityApp":
          if (useObject && module !== modules.MODULE_SCANNER) {
            if (objectRef.current) objectRef.current.focus();
          } else if (useLot && module !== modules.MODULE_SCANNER) {
            if (lotRef.current) lotRef.current.focus();
          } else if (buttonRef.current && !semiAutoMode) {
            buttonRef.current.focus();
          } else if (semiAutoMode) submitFunction();
          break;

        case "objectCode":
          if (useLot && module !== modules.MODULE_SCANNER) {
            if (lotRef.current) lotRef.current.focus();
          } else if (buttonRef.current && !semiAutoMode) {
            buttonRef.current.focus();
          } else if (semiAutoMode) submitFunction();
          break;

        case "lot":
          if (buttonRef.current && !semiAutoMode) {
            buttonRef.current.focus();
          } else if (semiAutoMode) submitFunction();
          break;
      }
    }
  };

  React.useEffect(() => {
    if (barcodeRef.current) barcodeRef.current.focus();
  }, []);

  const insertMinusSign = useCallback(() => {
    if (scannerForm) {
      const fieldState = scannerForm.getFieldState("quantityApp");
      const value = fieldState && fieldState.value ? -fieldState.value : -1;
      scannerForm.change("quantityApp", value);
      if (quantityRef.current) quantityRef.current.focus();
    }
  }, [scannerForm, quantityRef]);

  const handleMoreInfo = () => {
    if (module === modules.MODULE_PRODUCT) return;

    const barcode =
      barcodeRef && barcodeRef.current ? barcodeRef.current.value : "";
    const infoParams = {
      clientDbId: userDb,
      locale: locale,
      barcode: barcode,
      module: module,
      departmentId: departmentId,
    };

    const getInfo = isProductCustom
      ? getProductInfoCustom
      : getProductInfoByBarcode;

    if (barcode)
      getInfo(infoParams)
        .then(({ data }) => {
          if (data.length === 1) {
            const newInfo = { ...data[0] };
            setExtraInfo(newInfo);
            setOpenDrawer(true);
          }
        })
        .catch((err: any) => {
          setShowAlert(true);
          const message = getErrorMessage(err);

          setErrorMessage({
            message: message.message,
            error: true,
            status: message.status,
          });
        });
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setExtraInfo({});
  };

  //When extraInfo form is closed focus on barcode field
  React.useEffect(() => {
    if (!openDrawer && barcodeRef.current) barcodeRef.current.focus();
  }, [openDrawer]);

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.select();
  };

  React.useEffect(
    () => {
      if (
        scannedData &&
        scannedData.barcode !== "" &&
        module === modules.MODULE_PRODUCT
      )
        scan(scannedData);
    }, // eslint-disable-next-line
    [refresh]
  );

  React.useEffect(
    () => {
      const fields = moduleParams
        ? moduleParams.filter(
            (params: any) => params.parameter.toUpperCase() !== "OBJECTS"
          )
        : [];

      setFieldsParams(fields);

      const objects = moduleParams
        ? moduleParams
            .filter(
              (params: any) => params.parameter.toUpperCase() === "OBJECTS"
            )
            .map((operation: any) => ({
              label: operation.name,
              value:
                typeof operation.code !== "string"
                  ? JSON.stringify(operation.code)
                  : operation.code,
            }))
        : [];

      if (objects.length > 0) {
        objects.sort(function (a: any, b: any) {
          return a.value - b.value;
        });

        objects.unshift({
          label: translate("empty", "(tuščia)"),
          value: "",
        });

        setObjectsList(objects);
      }
    }, // eslint-disable-next-line
    [moduleParams]
  );

  const doesObjectExists = (value: any) => {
    let exists = undefined;
    const object = objectsList
      ? objectsList.filter((option: any) => option.value === value)
      : [];

    if (object.length === 0 && typeof value !== "undefined")
      exists = translate("codeDoesNotExist", "Toks kodas neegzistuoja!");
    return exists;
  };

  const handleExtraFields = () => {
    setShowExtra({ ...showExtra, show: true });
  };

  const handleCloseExtra = () => {
    setShowExtra({ ...showExtra, show: false });
  };

  const handleConfirmExtra = (extraFields: Record<string, unknown>) => {
    setShowExtra({ show: false, data: extraFields });
  };

  const valueToShow = React.useMemo(() => {
    let stringToShow = ``;

    const show1 =
      extra1.type === "$B"
        ? showExtra.data.extraInfo1 === true
          ? translate("true")
          : translate("false")
        : showExtra.data.extraInfo1;

    const show2 =
      extra2.type === "$B"
        ? showExtra.data.extraInfo2 === true
          ? translate("true")
          : translate("false")
        : showExtra.data.extraInfo2;

    const show3 =
      extra3.type === "$B"
        ? showExtra.data.extraInfo3 === true
          ? translate("true")
          : translate("false")
        : showExtra.data.extraInfo3;

    const show4 =
      extra4.type === "$B"
        ? showExtra.data.extraInfo4 === true
          ? translate("true")
          : translate("false")
        : showExtra.data.extraInfo4;

    const show5 =
      extra5.type === "$B"
        ? showExtra.data.extraInfo5 === true
          ? translate("true")
          : translate("false")
        : showExtra.data.extraInfo5;

    if (showExtra.data.note) stringToShow += `${showExtra.data.note} `;

    if (
      showExtra.data.extraInfo1 ||
      (extra1.type === "$B" && showExtra.data.extraInfo1 === false)
    )
      stringToShow += (stringToShow !== `` ? `|` : ``) + `${show1} `;
    if (
      showExtra.data.extraInfo2 ||
      (extra2.type === "$B" && showExtra.data.extraInfo2 === false)
    )
      stringToShow += (stringToShow !== `` ? `|` : ``) + `${show2} `;
    if (
      showExtra.data.extraInfo3 ||
      (extra3.type === "$B" && showExtra.data.extraInfo3 === false)
    )
      stringToShow += (stringToShow !== `` ? `|` : ``) + `${show3} `;
    if (
      showExtra.data.extraInfo4 ||
      (extra4.type === "$B" && showExtra.data.extraInfo4 === false)
    )
      stringToShow += (stringToShow !== `` ? `|` : ``) + `${show4} `;
    if (
      showExtra.data.extraInfo5 ||
      (extra5.type === "$B" && showExtra.data.extraInfo5 === false)
    )
      stringToShow += (stringToShow !== `` ? `|` : ``) + `${show5} `;

    return stringToShow;
  }, [showExtra.data, extra1, extra2, extra3, extra4, extra5, translate]);

  const handleCameraScanning = (result: any) => {
    if (result && result.isScanSuccess) {
      let activeEl: string | undefined;
      try {
        activeEl = document.activeElement?.id;
      } catch {
        activeEl = "";
      }

      if (
        activeEl &&
        (activeEl === "barcode" ||
          activeEl === "quantityApp" ||
          activeEl === "objectCode" ||
          activeEl === "lot")
      )
        scannerForm.change(activeEl, result.data);
      else scannerForm.change("barcode", result.data);

      if ("vibrate" in navigator) {
        // vibration API supported
        window.navigator.vibrate(300);
      }

      if (autoMode) scannerForm.submit();
    }
  };

  return (
    <div className={classes.container}>
      {showAlert && (
        <Alert
          message={errorMessage.message}
          isOpen={showAlert}
          onClose={handleCancelAlert}
          isConfirmationButton={!errorMessage.error}
          onConfirm={handleConfirmAlert}
          iconColor={!errorMessage.error ? "warning" : "error"}
        />
      )}

      {openDrawer && (
        <Drawer
          isOpen={openDrawer}
          onClose={handleCloseDrawer}
          data={extraInfo}
        />
      )}

      {showExtra.show && (
        <ExtraFieldsForm
          open={showExtra.show}
          onClose={handleCloseExtra}
          fieldsList={fieldsParams}
          onConfirm={handleConfirmExtra}
          initialValues={showExtra.data}
        />
      )}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={subscription}
        render={({ handleSubmit, submitError, form, errors }) => {
          submitFunction = handleSubmit;
          formResetFunction = form.reset;
          scannerForm = form;
          return (
            <form
              onSubmit={async (event) => {
                const error = await handleSubmit(event);
                if (error) {
                  return error;
                }
                form.reset();
              }}
              method="post"
            >
              {submitError && (
                <Typography color="error">{submitError}</Typography>
              )}

              {camera && <Camera2 onUpdate={handleCameraScanning} />}

              <OutlinedTextField
                type="text"
                name="barcode"
                inputProps={{ id: "barcode" }}
                required
                endAdornment={
                  module !== modules.MODULE_PRODUCT ? (
                    <Label
                      name={translate("barcode", "Barkodas")}
                      onClick={handleMoreInfo}
                      icon={<InfoOutlined fontSize="small" />}
                    />
                  ) : (
                    <Label name={translate("barcode", "Barkodas")} />
                  )
                }
                otherColor="white"
                onKeyPress={handleKeyPress}
                autoComplete="off"
                onFocus={handleFocus}
                ref={barcodeRef}
              />

              <WhenBarcodeChanges
                field="barcode"
                fieldAmount="quantityApp"
                fieldLot="lot"
                fieldObject="objectCode"
              />

              {module !== modules.MODULE_PRODUCT && (
                <OutlinedTextField
                  type="text"
                  name="quantityApp"
                  validate={isNumber}
                  inputProps={{ id: "quantityApp" }}
                  required
                  endAdornment={
                    <Label
                      name={translate("quantity", "Kiekis")}
                      onClick={insertMinusSign}
                      icon={<IsoOutlined fontSize="small" />}
                    />
                  }
                  otherColor="white"
                  onKeyPress={handleKeyPress}
                  autoComplete="off"
                  onFocus={handleFocus}
                  inputMode="decimal"
                  ref={quantityRef}
                />
              )}

              {useObject &&
                module !== modules.MODULE_SCANNER &&
                module !== modules.MODULE_PRODUCT &&
                (objectsList && objectsList.length > 0 ? (
                  <OutlinedAutocomplete
                    name="objectCode"
                    fullWidth
                    id="objectCode"
                    endAdornment={
                      <Label name={translate("object", "Objektas")} />
                    }
                    otherColor="white"
                    onKeyPress={handleKeyPress}
                    onFocus={handleFocus}
                    ref={objectRef}
                    options={objectsList}
                    validate={doesObjectExists}
                  />
                ) : (
                  <OutlinedTextField
                    type="text"
                    name="objectCode"
                    inputProps={{ id: "objectCode" }}
                    endAdornment={
                      <Label name={translate("object", "Objektas")} />
                    }
                    otherColor="white"
                    onKeyPress={handleKeyPress}
                    autoComplete="off"
                    onFocus={handleFocus}
                    ref={objectRef}
                  />
                ))}

              {useLot &&
                module !== modules.MODULE_SCANNER &&
                module !== modules.MODULE_PRODUCT && (
                  <OutlinedTextField
                    type="text"
                    name="lot"
                    inputProps={{ id: "lot" }}
                    endAdornment={<Label name={translate("lot", "Serija")} />}
                    otherColor="white"
                    onKeyPress={handleKeyPress}
                    autoComplete="off"
                    onFocus={handleFocus}
                    ref={lotRef}
                  />
                )}

              {useExtra && module !== modules.MODULE_PRODUCT && (
                <TextField
                  type="text"
                  endAdornment={
                    <Label
                      name={translate("exInfo", "Pap. info")}
                      onClick={handleExtraFields}
                      icon={<EditOutlined fontSize="small" />}
                    />
                  }
                  value={valueToShow}
                  otherColor="white"
                  onKeyPress={handleKeyPress}
                  autoComplete="off"
                  onFocus={handleFocus}
                  disabled
                />
              )}

              <div
                className={cx({
                  [classes.button]: !autoMode && !semiAutoMode,
                })}
              >
                <Button
                  endIcon={<ArrowForward />}
                  display={autoMode || semiAutoMode ? "none" : undefined}
                  type={autoMode ? "submit" : undefined}
                  ref={buttonRef}
                  disabled={
                    typeof errors === "object" &&
                    Object.keys(errors).length !== 0
                  }
                  onClick={async (event) => {
                    const submitError = await handleSubmit(event);
                    if (submitError) {
                      return submitError;
                    }
                    form.reset();
                    form.change("lot", "");
                    form.change("quantityApp", 1);
                    form.change("objectCode", "");
                  }}
                  fullWidth={true}
                >
                  {translate("continue", "Tęsti")}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

export default ScannerForm;
