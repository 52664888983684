import { makeStyles } from "tss-react/mui";

const useHeaderFieldStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    lineHeight: "23px",
    alignItems: "center",
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "80%",
    overflow: "hidden",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    textAlign: "right",
    width: "20%",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
  },
  bottomLeft: {
    fontSize: "1rem",
    color: theme.palette.grey.A700,
  },
  columnsRight: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "2px",
  },
  grey: {
    backgroundColor: theme.palette.grey.A100,
    margin: "0px 15px",
  },
  limitedHeight: {
    height: "58.8px",
    margin: "5px 0",
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  noColor: {
    backgroundColor: "inherit",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  arrow: {
    lineHeight: "5px",
  },
  lines: {
    lineHeight: "21px",
  },
  expand: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

export default useHeaderFieldStyles;
