import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export interface IUpdateProductQty {
  clientDbId: number;
  locale: string;
  operationCode: string;
  productQuantity: number;
  editComponents: boolean;
}

export const updateProductQty = (params: IUpdateProductQty) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_EDIT_H_ASSEMBLY",
    data: {
      operationCode: params.operationCode,
      quantityProduct: params.productQuantity,
      editComponents: params.editComponents ? 1 : 0,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface ICreateAssemblyOperation {
  clientDbId: number;
  locale: string;
  productCode: string;
  departmentId?: string;
  documentType: string;
  description: string;
  quantity: number | string;
}

export const createAssemblyOperation = (params: ICreateAssemblyOperation) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_CREATE_KA_DOC",
    data: {
      documentType: params.documentType,
      description: params.description,
      productCode: params.productCode,
      departmentId: params.departmentId,
      quantity: params.quantity,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IGetParamsList {
  clientDbId: number;
  locale: string;
}

export const getAssemblyParams = (params: IGetParamsList) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_ASSEMBLY_PARAMS",
    locale: params.locale,
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IGetProductsList {
  clientDbId: number;
  pageNumber: number;
  fil?: string;
}

export const getProductsList = (
  params: IGetProductsList,
  config?: Record<string, unknown>
) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_PRODUCTS",
    data: {
      pageNumber: params.pageNumber,
      fil: params.fil || "",
      top: 100,
    },
  };

  return client
    .post("/client/application", query, config)
    .then(formatAppListResponse);
};
