import { makeStyles } from "tss-react/mui";

const useSettingsStyles = makeStyles()((theme) => ({
  root: {
    width: "90%",
    margin: `${theme.spacing(1)} auto`,
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  dbList: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fieldsList: {
    paddingBottom: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    height: "1px",
    opacity: "0.15",
  },
  groupLegend: {
    color: theme.palette.common.white,
  },
  settingsGroup: {
    marginLeft: "11px",
  },
  version: {
    color: "white",
    fontSize: "0.9rem",
    textAlign: "right",
  },
  chooseVersion: {
    padding: "10px 0",
    color: theme.palette.common.white,
  },
  versionBtn: {
    paddingBottom: "10px",
    width: "100%",

    "@media (min-width: 530px)": {
      width: "50%",
    },
  },
}));

export default useSettingsStyles;
