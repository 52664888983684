export function isAlreadySet(array: any, user: string | undefined) {
  return array && Array.isArray(array)
    ? array.filter((person: any) => person.userId === user)
    : [];
}

export function otherUsersSettings(array: any, user: string | undefined) {
  return array && Array.isArray(array)
    ? array.filter((person: any) => person.userId !== user)
    : [];
}

export function getUserValue(array: any, user: string | undefined) {
  const params =
    array && Array.isArray(array)
      ? array.filter((person: any) => person.userId === user)
      : [];
  let value = "";

  if (params.length > 0) value = params[0].value;
  return value;
}
