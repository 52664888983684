import React, { useState } from "react";
import { InventoryList, InventoryFormCreate } from "./components";
import { BaseLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import * as routes from "../../app/routes";
import { Add } from "@mui/icons-material";

function Inventory() {
  const [translate] = useTranslation();
  const [createOp, setCreateOp] = useState(false);

  const handleClick = () => {
    setCreateOp(true);
  };

  const handleDialogClosing = () => {
    setCreateOp(false);
  };
  return (
    <BaseLayout
      moduleName={translate("inventory", "Inventorizacija")}
      modulePath={routes.ROUTE_INVENTORY.path}
      isDocumentList
      button={{
        btnIcon: <Add />,
        btnText: translate("new", "Nauja"),
        btnOnClick: handleClick,
      }}
    >
      {createOp && (
        <InventoryFormCreate open={createOp} onClose={handleDialogClosing} />
      )}

      <InventoryList />
    </BaseLayout>
  );
}

export default Inventory;
