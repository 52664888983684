import { client } from "./utils";
import { formatListResponse } from "./utils/helpers";

export const getUsersDbList = (userId: string) => {
  const query = {
    filter: {
      junction: "AND",
      entities: [
        {
          field: "userId",
          operation: "=",
          value: userId,
        },
        {
          field: "applicationName",
          operation: "=",
          value: "MSCAN",
        },
      ],
    },
  };

  return client
    .post("/account/client-db-app-user/query", query)
    .then(formatListResponse);
};
