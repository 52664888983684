import React from "react";
import { Button } from "../../components";
import { useFormState } from "react-final-form";

interface IButtonProps {
  children?: string | JSX.Element;
  endIcon?: React.ReactNode;
  display?: "none";
}
const SubmitButton = React.forwardRef<any, IButtonProps>((props, ref) => {
  const { pristine, submitting } = useFormState({
    subscription: { pristine: true, submitting: true },
  });

  return (
    <Button
      type="submit"
      fullWidth={true}
      disabled={submitting || pristine}
      ref={ref}
      {...props}
    >
      {props.children || "Išsaugoti"}
    </Button>
  );
});

export default React.memo(SubmitButton);
