import React from "react";
import { useLabelStyles } from "./css";

interface ILabel {
  name: string;
  onClick?: () => void;
  icon?: JSX.Element;
}

function Label(props: ILabel) {
  const { name, onClick, icon } = props;
  const { classes } = useLabelStyles();

  return (
    <div className={classes.label} onClick={onClick}>
      <span>{name}</span>
      {icon}
    </div>
  );
}

export default Label;
