import React from "react";
import { Button as MuiButton } from "@mui/material";
import { useButtonStyles } from "./css";

interface IButton {
  children?: React.ReactNode;
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  color?:
    | "inherit"
    | "error"
    | "warning"
    | "primary"
    | "secondary"
    | "success"
    | "info";
  type?: "submit";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  href?: string;
  disableElevation?: boolean;
  otherColors?: "white" | "error" | "warning";
  display?: "none";
}

const Button = React.forwardRef<any, IButton>((props, ref) => {
  const { color = "primary", children, otherColors, display, ...rest } = props;
  const { classes, cx } = useButtonStyles();

  return (
    <MuiButton
      className={cx(classes.root, {
        [classes.white]: otherColors === "white",
        [classes.warning]: otherColors === "warning",
        [classes.error]: otherColors === "error",
        [classes.display]: display === "none",
      })}
      variant="contained"
      color={color}
      ref={ref}
      {...rest}
    >
      {children}
    </MuiButton>
  );
});

export default Button;
