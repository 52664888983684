import { makeStyles } from "tss-react/mui";

const useLoginLayoutStyles = makeStyles()((theme) => ({
  layout: {
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
  },
  extralayout: {
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
    height: "100vh",
  },
}));

export default useLoginLayoutStyles;
