import { makeStyles } from "tss-react/mui";

const useItemFieldStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 0",
    lineHeight: "23px",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "80%",
    overflow: "hidden",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    textAlign: "right",
    width: "20%",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
    color: theme.palette.grey.A700,
  },
  bottomLeft: {
    fontSize: "1rem",
    color: theme.palette.grey.A700,
  },
  columnsRight: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "2px",
  },
  arrow: {
    lineHeight: "5px",
    opacity: "0.5",
  },
  lines: {
    lineHeight: "21px",
  },
  limitedHeight: {
    height: "58.8px",
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  collectedItem: {
    color: theme.palette.primary.main,
  },
  selectedRow: {
    animation: "$target-fade 3s 1",
    WebkitAnimation: "$target-fade 3s 1",
    MozAnimation: "$target-fade 3s 1",
  },
  "@keyframes target-fade": {
    "0%": {
      backgroundColor: "rgba(0, 179, 115,0.8)",
    },
    "100%": {
      backgroundcolor: "rgba(0, 179, 115,0)",
    },
  },
  collectedRow: {
    backgroundColor: "rgba(0,0,0,0.2)",
  },
}));

export default useItemFieldStyles;
