import React, { useEffect, useState } from "react";
import { Dialog, Alert } from "../../../components";
import {
  createSalesPurchasesOperation,
  getSalesPurchasesParams,
} from "../../api/salesPurchases";
import { uiLanguage, userClientDb } from "../../auth/selectors/userSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getErrorMessage,
  getWarningMessage,
} from "../../../components/form/formUtils";
import { Navigate } from "react-router-dom";
import * as routes from "../../../app/routes";
import { Grid, Autocomplete, TextField } from "@mui/material";
import { Form } from "react-final-form";
import {
  OutlinedSelectField,
  OutlinedTextField,
  OutlinedAutocomplete,
  WhenFieldChanges,
  ListboxComponent,
} from "../../../components/form";
import { required } from "../../../utils/validate";
import {
  useLoader,
  useClientsFetching,
  useAddressFetching,
} from "../../../hooks";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useOutlinedAutocompleteStyles } from "../../../components/form/css";

interface ISalesCreate {
  open: boolean;
  onClose: (event?: Record<string, unknown>) => void;
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

let id = "";

function SalesCreate(props: ISalesCreate) {
  const { open, onClose } = props;
  const { classes, cx } = useOutlinedAutocompleteStyles();
  const [descriptionOptions, setDescriptionOptions] =
    useState<Array<{ value: string; label: string }>>();
  const [documentTypeOptions, setDocumentTypeOptions] =
    useState<Array<{ value: string; label: string }>>();
  const [redirect, setRedirect] = useState({ redirect: false, id: "" });
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    error: false,
  });
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const [, setLoader] = useLoader();
  const [selectedClient, setSelectedClient] = useState("");
  const [showAddress, setShowAddress] = useState(false);

  const params = {
    clientDbId: userDb ? userDb.id : 0,
    locale: locale,
    module: "RO",
  };

  const [fil, setFil] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const { loading, error, clients, hasMore } = useClientsFetching(
    fil,
    pageNumber,
    "RO"
  );

  const [filAddr, setFilAddr] = useState("");
  const [pageNumberAddr, setPageNumberAddr] = useState(1);
  const {
    loading: loadingAddr,
    error: errorAddr,
    addresses,
    hasMore: hasMoreAddr,
  } = useAddressFetching(filAddr, pageNumberAddr, selectedClient, "RO");

  const [addr, setAddr] = useState<{ label: any; value: string } | null>(null);

  useEffect(() => {
    if (error) {
      const message = getErrorMessage(error);
      setShowAlert({ show: true, message: message.message, error: true });
    }

    if (errorAddr) {
      const message = getErrorMessage(errorAddr);
      setShowAlert({ show: true, message: message.message, error: true });
    }
  }, [error, errorAddr]);

  const documentTypes = [
    { value: "1", label: translate("invoice", "Važtaraštis") },
    { value: "2", label: translate("return", "Grąžinimas") },
    { value: "3", label: translate("order", "Užsakymas") },
    { value: "4", label: translate("reservation", "Rezervavimas") },
    { value: "5", label: translate("offer", "Pasiūlymas") },
  ];

  const initialValues = React.useMemo(
    () => ({
      documentType:
        documentTypeOptions && documentTypeOptions[0]
          ? documentTypeOptions[0].value
          : "",
      client: "",
      descriptionCode1: "",
    }),
    [documentTypeOptions]
  );

  React.useEffect(
    () => {
      setLoader(true);
      getSalesPurchasesParams(params)
        .then(({ data }) => {
          setLoader(false);
          if (data && data.length > 0) {
            const opArray2 = data
              .filter(
                (operation: any) =>
                  operation.parameter.toLowerCase() === "description"
              )
              .map((operation: any) => ({
                label: operation.code,
                value:
                  typeof operation.code !== "string"
                    ? JSON.stringify(operation.code)
                    : operation.code,
              }));

            opArray2.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDescriptionOptions(opArray2);

            // Documents types list
            const opArray3 = data.filter(
              (operation: any) =>
                operation.parameter.toLowerCase() === "doctypes"
            );

            const docArray = documentTypes.filter((type: any) => {
              return opArray3.some((el: any) => {
                return (
                  (typeof el.code !== "string"
                    ? JSON.stringify(el.code)
                    : el.code) === type.value
                );
              });
            });

            docArray.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setDocumentTypeOptions(docArray);

            //show address field
            const showAddressField = data.find(
              (operation: any) =>
                operation.parameter.toLowerCase() === "showaddress"
            );

            if (
              showAddressField &&
              showAddressField["code"] &&
              showAddressField["code"] === ".T."
            )
              setShowAddress(true);
          }
        })
        .catch((reason) => {
          setLoader(false);
          const message = getErrorMessage(reason);
          setShowAlert({ show: true, message: message.message, error: true });
        });
    }, // eslint-disable-next-line
    []
  );

  const handleClientSearch = (value: string) => {
    setFil(
      `clientName like '%${value || ""}%' or companyCode like '%${
        value || ""
      }%' or clientCode like '%${value || ""}' `
    );

    setSelectedClient(value);

    setPageNumber(1);
  };

  const handleAddressSearch = (
    e: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setFilAddr(value);

    setPageNumberAddr(1);
  };

  const clearAddressField = () => {
    setAddr(null);
  };

  const onAddressChange = (
    e: React.SyntheticEvent,
    value: { label: any; value: string } | null
  ) => {
    setAddr(value);
  };

  const handleErrorClose = () => {
    setShowAlert({ show: false, message: "", error: false });
    onClose();
  };

  const handleSubmit = (values: any) => {
    const opParams = {
      ...params,
      clientCode: values.client,
      description: values.description,
      documentType: values.documentType,
      address: addr?.value || "",
    };

    createSalesPurchasesOperation(opParams)
      .then(({ data, warnings }) => {
        if (warnings && warnings.length > 0) {
          id = warnings[0].operationCode;
          const message = getWarningMessage(warnings);
          setShowAlert({ show: true, message: message, error: false });
          return;
        }

        id = data[0].operationCode;
        setRedirect({ redirect: true, id: id });
      })
      .catch((reason) => {
        const message = getErrorMessage(reason);
        setShowAlert({ show: true, message: message.message, error: true });
      });
  };

  const handleWarningClose = React.useCallback(() => {
    setRedirect({ redirect: true, id: id });
  }, [setRedirect, id]);

  const showError = (
    <Alert
      isOpen={showAlert.show}
      message={showAlert.message}
      onClose={showAlert.error ? handleErrorClose : handleWarningClose}
      iconColor={showAlert.error ? "error" : "warning"}
    />
  );

  const loadNextPage = React.useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber, setPageNumber]);

  const loadNextPageAddre = React.useCallback(() => {
    setPageNumberAddr(pageNumberAddr + 1);
  }, [pageNumberAddr, setPageNumberAddr]);

  const clientsOptions = React.useMemo(() => {
    return [
      ...clients.map((client) => {
        return {
          label:
            client.companyCode +
            (client.companyCode === "" ? "" : ": ") +
            client.clientName,
          value:
            typeof client.clientCode !== "string"
              ? JSON.stringify(client.clientCode)
              : client.clientCode,
        };
      }),
    ];
  }, [clients]);

  const addressesOptions = React.useMemo(() => {
    return [
      ...addresses.map((address) => {
        return {
          label: address.label ? address.label : "" + address.value,
          value: "" + address.value,
        };
      }),
    ];
  }, [addresses]);

  return (
    <div>
      {showAlert.show && showError}
      {redirect.redirect ? (
        <Navigate to={routes.ROUTE_SALES_DETAILS.path + "/" + redirect.id} />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          subscription={subscription}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} method="post">
              <Dialog
                open={open}
                onClose={onClose}
                title={translate("createOperation", "Nauja operacija")}
                isConfirmationButton
                onConfirm={handleSubmit}
              >
                <Grid container spacing={1} direction="column">
                  <Grid item xs={12}>
                    <OutlinedSelectField
                      name="documentType"
                      fullWidth
                      label={translate("docType", "Dokumento tipas")}
                      options={documentTypeOptions}
                      otherColor="grey"
                      validate={required}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OutlinedAutocomplete
                      name="client"
                      fullWidth
                      label={translate("client", "Klientas")}
                      loading={loading}
                      options={clientsOptions}
                      otherColor="grey"
                      validate={required}
                      required
                      renderType="renderExtra"
                      ListboxComponent={
                        ListboxComponent as React.ComponentType<
                          React.HTMLAttributes<HTMLElement>
                        >
                      }
                      ListboxProps={{
                        hasNextPage: hasMore,
                        isNextPageLoading: loading,
                        loadNextPage: loadNextPage,
                      }}
                    />

                    <WhenFieldChanges
                      field="client"
                      onChange={handleClientSearch}
                    />
                  </Grid>

                  {showAddress && (
                    <Grid item xs={12}>
                      <Autocomplete
                        name="address"
                        fullWidth
                        loading={loadingAddr}
                        options={addressesOptions}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        size="small"
                        value={addr}
                        noOptionsText={translate(
                          "noOptions",
                          "Nėra tokio pasirinkimo"
                        )}
                        loadingText={translate("loading")}
                        onChange={onAddressChange}
                        onInputChange={handleAddressSearch}
                        popupIcon={<ExpandMoreOutlined />}
                        classes={{
                          root: cx(classes.root, classes.greyBorder),
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate(
                              "alternativeAddress",
                              "Alternatyvus adresas"
                            )}
                            classes={{
                              root: cx(classes.root, classes.greyBorder),
                            }}
                          />
                        )}
                        ListboxComponent={
                          ListboxComponent as React.ComponentType<
                            React.HTMLAttributes<HTMLElement>
                          >
                        }
                        ListboxProps={{
                          // @ts-ignore
                          hasNextPage: hasMoreAddr,
                          isNextPageLoading: loadingAddr,
                          loadNextPage: loadNextPageAddre,
                        }}
                      />

                      <WhenFieldChanges
                        field="client"
                        onChange={clearAddressField}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    {descriptionOptions && descriptionOptions.length > 0 ? (
                      <OutlinedAutocomplete
                        name="description"
                        fullWidth
                        label={translate("remarks", "Pastabos")}
                        options={descriptionOptions}
                        otherColor="grey"
                        renderType="renderSimple"
                      />
                    ) : (
                      <OutlinedTextField
                        name="description"
                        fullWidth
                        label={translate("remarks", "Pastabos")}
                        otherColor="grey"
                        autoComplete="off"
                        maxLength={60}
                      />
                    )}
                  </Grid>
                </Grid>
              </Dialog>
            </form>
          )}
        />
      )}
    </div>
  );
}

export default SalesCreate;
