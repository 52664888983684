export const ROUTE_LOGIN = { path: "/login" };
export const ROUTE_OPTIONS = { path: "/options" };
export const ROUTE_SETTINGS = { path: "/settings" };
export const ROUTE_SALES = { path: "/sales" };
export const ROUTE_SALES_DETAILS = { path: "/sales_details" };
export const ROUTE_PURCHASES = { path: "/purchases" };
export const ROUTE_PURCHASES_DETAILS = { path: "/purchases_details" };
export const ROUTE_INTERNAL = { path: "/internal" };
export const ROUTE_INTERNAL_DETAILS = { path: "/internal_details" };
export const ROUTE_INVENTORY = { path: "/inventory" };
export const ROUTE_INVENTORY_DETAILS = { path: "/inventory_details" };
export const ROUTE_SCANNER = { path: "/scanner" };
export const ROUTE_SCANNER_DETAILS = { path: "/scanner_details" };
export const ROUTE_COMPLETION = { path: "/completion" };
export const ROUTE_PRODUCT_INFO = { path: "/product" };
export const ROUTE_ASSEMBLY = { path: "/assembly" };
export const ROUTE_ASSEMBLY_DETAILS = { path: "/assembly_details" };
export const ROUTE_ERROR = { path: "/error" };
