import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Assembly, AssemblyDetails } from "../modules/assembly";
import Auth from "../modules/auth";
import { isLoggedIn } from "../modules/auth/selectors/userSelector";
import Completion from "../modules/completion";
import {
  InternalMovements,
  InternalMovementsDetails,
} from "../modules/internalMovements";
import { Inventory, InventoryDetails } from "../modules/inventory";
import Options from "../modules/options";
import { ProductInfo } from "../modules/productInfo";
import { PurchaseDetails, Purchases } from "../modules/purchases";
import { Sales, SalesDetails } from "../modules/sales";
import { Scanner, ScannerDetails } from "../modules/scanner";
import Settings from "../modules/settings";
import { getTokenExpirationDate } from "../utils/authToken";
import * as routes from "./routes";
import { ErrorPage } from "../components";

export default function AppRoutes() {
  const expCookie = getTokenExpirationDate();
  const isUserLoggedIn = useSelector(isLoggedIn) && !!expCookie;
  const navigate = useNavigate();

  const loginPath = routes.ROUTE_LOGIN.path;

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("/v3/")) {
      navigate(currentPath, { replace: true });
    }
  }, []);

  return (
    <Routes>
      <Route
        path={routes.ROUTE_LOGIN.path}
        element={
          isUserLoggedIn ? (
            <Navigate to={routes.ROUTE_OPTIONS.path} />
          ) : (
            <Auth />
          )
        }
      />

      <Route
        path="/"
        element={
          <Navigate
            to={routes.ROUTE_LOGIN.path}
            state={{
              redirect: true,
            }}
          />
        }
      />

      <Route
        path={routes.ROUTE_OPTIONS.path}
        element={isUserLoggedIn ? <Options /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_SETTINGS.path}
        element={isUserLoggedIn ? <Settings /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_SALES_DETAILS.path + "/:id"}
        element={
          isUserLoggedIn ? <SalesDetails /> : <Navigate to={loginPath} />
        }
      />

      <Route
        path={routes.ROUTE_SALES.path}
        element={isUserLoggedIn ? <Sales /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_PURCHASES_DETAILS.path + "/:id"}
        element={
          isUserLoggedIn ? <PurchaseDetails /> : <Navigate to={loginPath} />
        }
      />

      <Route
        path={routes.ROUTE_PURCHASES.path}
        element={isUserLoggedIn ? <Purchases /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_INTERNAL_DETAILS.path + "/:id"}
        element={
          isUserLoggedIn ? (
            <InternalMovementsDetails />
          ) : (
            <Navigate to={loginPath} />
          )
        }
      />

      <Route
        path={routes.ROUTE_INTERNAL.path}
        element={
          isUserLoggedIn ? <InternalMovements /> : <Navigate to={loginPath} />
        }
      />

      <Route
        path={routes.ROUTE_SCANNER_DETAILS.path + "/:id"}
        element={
          isUserLoggedIn ? <ScannerDetails /> : <Navigate to={loginPath} />
        }
      />

      <Route
        path={routes.ROUTE_SCANNER.path}
        element={isUserLoggedIn ? <Scanner /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_INVENTORY_DETAILS.path + "/:id"}
        element={
          isUserLoggedIn ? <InventoryDetails /> : <Navigate to={loginPath} />
        }
      />

      <Route
        path={routes.ROUTE_INVENTORY.path}
        element={isUserLoggedIn ? <Inventory /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_COMPLETION.path + "/:module/:id"}
        element={isUserLoggedIn ? <Completion /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_PRODUCT_INFO.path}
        element={isUserLoggedIn ? <ProductInfo /> : <Navigate to={loginPath} />}
      />

      <Route
        path={routes.ROUTE_ASSEMBLY_DETAILS.path + "/:id"}
        element={
          isUserLoggedIn ? <AssemblyDetails /> : <Navigate to={loginPath} />
        }
      />

      <Route
        path={routes.ROUTE_ASSEMBLY.path}
        element={isUserLoggedIn ? <Assembly /> : <Navigate to={loginPath} />}
      />

      <Route path={routes.ROUTE_ERROR.path} element={<ErrorPage />} />

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
