import React from "react";
import { Field } from "react-final-form";
import CheckBox from "../CheckBox";

interface IOutlinedCheckBox {
  validate?: (value: any) => any;
  name: string;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => void;
  disabled?: boolean;
  labelColor?: "black" | "white";
  label?: string;
}

const OutlinedCheckBox = React.forwardRef<any, IOutlinedCheckBox>(
  (props, ref) => {
    const { disabled, label, labelColor, onKeyPress, ...rest } = props;

    return (
      <Field
        type="checkbox"
        render={({
          input,
          meta,
          ...restTextField
        }: {
          [name: string]: any;
        }) => {
          return (
            <div>
              <CheckBox
                {...restTextField}
                {...input}
                disabled={meta.submitting || disabled}
                label={label}
                labelColor={labelColor}
                ref={ref}
                onKeyPress={onKeyPress}
              />
            </div>
          );
        }}
        {...rest}
      />
    );
  }
);

export default React.memo(OutlinedCheckBox);
