interface IAppResponse {
  data: { app: []; appWarnings: [] };
}

interface IResponse {
  data: { content: []; totalElements: number };
}

export function formatAppListResponse(response: IAppResponse) {
  const { app: dataArray = [], appWarnings: warningsArray = [] } =
    response.data;
  let data = [] as any;
  let warnings = [] as any;
  if (!Array.isArray(dataArray)) {
    data.push(dataArray);
  } else {
    data = [...dataArray];
  }

  if (!Array.isArray(warningsArray)) {
    warnings.push(warningsArray);
  } else {
    warnings = [...warningsArray];
  }

  return { data, warnings };
}

export function formatListResponse(response: IResponse) {
  const { content: data = [], totalElements: totalItems = 0 } = response.data;
  return { data, totalItems };
}
