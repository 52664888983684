import React from "react";
import { Field } from "react-final-form";
import { showError } from "./formUtils";
import TextField, { ITextFieldProps } from "../TextField";

interface IOutlinedTextField extends ITextFieldProps {
  validate?: (value: any) => any;
  name: string;
  otherColor?: "white" | "grey";
  inputMode?: "numeric" | "decimal";
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => void;
  maxLength?: number;
}

const OutlinedTextField = React.forwardRef<any, IOutlinedTextField>(
  (props, ref) => {
    const {
      disabled,
      helperText: userHelperText,
      endAdornment,
      label,
      otherColor,
      inputMode,
      onKeyPress,
      onFocus,
      maxLength,
      ...rest
    } = props;

    return (
      <Field
        render={({ input, meta, ...restTextField }) => {
          const isError = showError(meta);
          const helperText = isError ? meta.error : userHelperText;

          return (
            <div>
              <TextField
                {...restTextField}
                {...input}
                disabled={meta.submitting || disabled}
                error={isError}
                helperText={helperText}
                label={label}
                endAdornment={endAdornment}
                otherColor={otherColor}
                onKeyPress={onKeyPress}
                onFocus={onFocus}
                inputMode={inputMode}
                ref={ref}
                maxLength={maxLength}
              />
            </div>
          );
        }}
        {...rest}
      />
    );
  }
);

export default React.memo(OutlinedTextField);
