import { logOut } from "../modules/auth/slices/authSlice";
import { useDispatch } from "react-redux";

function useAuthorizationError() {
  const dispatch = useDispatch();

  const logoutUser = (errCode: number) => {
    if (errCode === 401) {
      dispatch(logOut());
    }
  };

  return [logoutUser];
}

export default useAuthorizationError;
