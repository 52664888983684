import React, { useEffect, useState } from "react";
import { getUsersDbList } from "../../api/clientDbAppUsers";
import { userId, userClientDb } from "../../auth/selectors/userSelector";
import { useDispatch, useSelector } from "react-redux";
import { setUserDbId } from "../../auth/slices/userSlice";
import {
  useLocalStorage,
  useAuthorizationError,
  useLoader,
} from "../../../hooks";
import { Select, Alert } from "../../../components";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "../../../components/form/formUtils";

function DbList() {
  const [translate] = useTranslation();
  const user = useSelector(userId);
  const userDb = useSelector(userClientDb);
  const [logoutUser] = useAuthorizationError();
  const dispatch = useDispatch();
  const [data, setData] = useState<Array<{ value: number; label: string }>>();
  const [localStorage, setLocalStorage] = useLocalStorage("userDb");
  const [error, setError] = useState({ error: false, message: "", status: 0 });
  const selectedDb = data && userDb ? userDb.id : "";
  const [, setLoader] = useLoader();

  const assignDb = React.useCallback(
    (selectedValue) => {
      const selectedDb =
        data &&
        data
          .filter((item) => item.value && item.value === selectedValue)
          .map((item) => {
            return { id: item.value, name: item.label };
          });

      if (selectedDb && selectedDb[0]) {
        const isUser =
          localStorage && Array.isArray(localStorage)
            ? localStorage.filter((person: any) => person.userId === user)
            : [];

        if (isUser.length === 0) {
          if (localStorage && localStorage.length > 0) {
            const newStorage = [
              ...localStorage,
              { ...selectedDb[0], userId: user },
            ];
            setLocalStorage(newStorage);
          } else {
            setLocalStorage([{ ...selectedDb[0], userId: user }]);
          }

          dispatch(setUserDbId({ ...selectedDb[0], userId: user ? user : "" }));
        } else {
          dispatch(setUserDbId({ ...selectedDb[0], userId: user ? user : "" }));

          const newStorage = localStorage.filter(
            (person: any) => person.userId !== user
          );
          setLocalStorage([...newStorage, { ...selectedDb[0], userId: user }]);
        }
      }
    }, // eslint-disable-next-line
    [dispatch, data, setLocalStorage]
  );

  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    assignDb(selectedValue);
  };

  useEffect(
    () => {
      if (user) {
        setLoader(true);
        getUsersDbList(user)
          .then(({ data }) => {
            setLoader(false);
            const dbList = data.map((item: any) => {
              return {
                value: item.clientDbId,
                label: item.companyName || item.clientDbName,
              };
            });
            setData(dbList);
          })
          .catch((reason) => {
            setLoader(false);
            const errMessage = getErrorMessage(reason);
            setError({
              error: true,
              message: errMessage.message,
              status: errMessage.status,
            });
          });
      }
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (!userDb && data && data.length === 1) assignDb(data[0].value);
    }, // eslint-disable-next-line
    [data]
  );

  const handleClose = () => {
    logoutUser(error.status);

    if (error.status !== 401)
      setError({ error: false, message: "", status: 0 });
  };

  const showError = (
    <Alert
      isOpen={error.error}
      message={error.message}
      iconColor="error"
      onClose={handleClose}
    />
  );

  return (
    <div>
      {error.error && showError}
      <Select
        options={data}
        onChange={onClick}
        value={selectedDb}
        label={translate("database", "Duomenų bazė")}
        fullWidth={true}
      />
    </div>
  );
}

export default DbList;
