import React from "react";
import { LoginLayout } from "../../layouts";
import SettingsList from "./components/SettingsList";

function Settings() {
  return (
    <LoginLayout>
      <SettingsList />
    </LoginLayout>
  );
}

export default Settings;
