import { makeStyles } from "tss-react/mui";

const useDialogStyles = makeStyles()((theme) => ({
  contentRoot: {
    "& .MuiDialog-paper": {
      margin: "10px",
      overflowX: "hidden",
    },
    "& .MuiDialog-paperFullWidth": {
      width: "calc(100% - 20px)",
    },
  },
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    display: "inline-block",
  },
  dialogTitle: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  dialogTitleSmallScreen: {
    padding: "5px 8px",
  },
  dialogContentSmallScreen: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  dialogContent: {
    paddingTop: "5px !important",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.common.black,
  },
}));

export default useDialogStyles;
