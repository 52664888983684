import React from "react";
import * as routes from "../../app/routes";
import { useTranslation } from "react-i18next";
import { BaseLayout } from "../../layouts";
import ProductInfoForm from "./components/ProductInfoForm";

function ProductInfo() {
  const [translate] = useTranslation();

  return (
    <BaseLayout
      moduleName={translate("productInformation", "Prekės informacija")}
      isDocumentList
      modulePath={routes.ROUTE_PRODUCT_INFO.path}
    >
      <ProductInfoForm />
    </BaseLayout>
  );
}

export default ProductInfo;
