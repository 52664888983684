import { useState, useEffect } from "react";
import { getProductsList } from "../modules/api/assembly";
import { userClientDb } from "../modules/auth/selectors/userSelector";
import { useSelector } from "react-redux";
import axios, { Canceler } from "axios";

function useProductsFetching(fil: string, pageNumber: number) {
  const clientDb = useSelector(userClientDb);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [products, setProducts] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setProducts([]);
  }, [fil]);

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    let cancel: Canceler;

    const query = {
      clientDbId: clientDb ? clientDb.id : 0,
      pageNumber: pageNumber,
      fil: fil,
    };

    const config = {
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    };

    getProductsList(query, config)
      .then(({ data }) => {
        setProducts((prevProducts: any[]) => {
          return [...prevProducts, ...data];
        });
        setLoading(false);
        // setHasMore(data.length > 0);
        setHasMore(data.length > 0 && data[0].pageNumber > pageNumber);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setError(err);
      });

    return () => cancel();
  }, [fil, pageNumber, clientDb]);

  return { loading, error, products, hasMore };
}

export default useProductsFetching;
