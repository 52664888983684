import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Arrow } from "../../logo";
import { useHeaderFieldStyles } from "./css";
import { HeaderMenu } from "../index";
import { moduleType } from "../../utils/modules";
import { userExpandRows } from "../../modules/auth/selectors/userSelector";

interface ICellRenderer {
  data: {
    operationCode: string;
    stateIndicator?: string;
    element1?: string;
    element2?: string;
    appPositions?: number;
    documentPositions?: number;
    operationType?: string;
  };
  link?: string;
  linkId?: "operationCode";
  headerColor?: "grey" | "noColor";
  module?: moduleType | "";
}

function HeaderField(props: ICellRenderer) {
  const { data, link = "", linkId, headerColor = "noColor", module } = props;
  const expandRows = useSelector(userExpandRows);
  const [anchorEl, setAnchorEl] = useState(null);
  const appState = data && data.stateIndicator ? data.stateIndicator : "N";
  const opTip =
    (data && data.operationType && data.operationType.toUpperCase())?.charAt(
      0
    ) || "";
  const linkTo = linkId && data ? `${link}/${data[linkId]}` : link;
  const { classes, cx } = useHeaderFieldStyles();
  const [redirect, setRedirect] = useState(false);
  const docPos =
    data && data.documentPositions ? parseInt(`${data.documentPositions}`) : 0;
  const appPos =
    data && data.appPositions ? parseInt(`${data.appPositions}`) : 0;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const headerInfo = (
    <div
      className={cx(classes.container, {
        [classes.limitedHeight]: !expandRows,
      })}
    >
      <div
        className={cx(classes.left, {
          [classes.limitedHeight]: !expandRows,
        })}
      >
        <div className={cx({ [classes.noWrap]: !expandRows })}>
          {data ? data.element1 : ""}
        </div>
        <div
          className={cx(classes.bottomLeft, {
            [classes.noWrap]: !expandRows,
          })}
        >
          {data ? data.element2 : ""}
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.columnsRight}>
          {data &&
          data.operationType &&
          data.operationType.toUpperCase() !== "KAUP" ? (
            <div>
              <div className={classes.lines}>
                {appPos !== undefined ? appPos : 0}
              </div>
              <div className={classes.arrow}>
                <Arrow width="7px" />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={classes.lines}>
            {docPos !== undefined ? docPos : 0}
          </div>
        </div>
        <div className={classes.columnsRight}>
          {data &&
          data.operationType &&
          data.operationType.toUpperCase() !== "KAUP" ? (
            opTip === "A" ? (
              <div className="iconA">{opTip}</div>
            ) : (
              <div className="iconP">{opTip}</div>
            )
          ) : (
            ""
          )}

          {appState === "K" ? (
            <div className="iconK">{appState}</div>
          ) : (
            <div className="iconN">{appState}</div>
          )}
        </div>
      </div>
    </div>
  );

  const handleClick = (event: any) => {
    if (linkId) {
      setRedirect(true);
    } else {
      setAnchorEl(anchorEl !== null ? null : event.currentTarget);
    }
  };

  return (
    <div>
      {Boolean(anchorEl) && !linkId && (
        <HeaderMenu
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          data={data}
          module={module}
        />
      )}

      <div
        className={cx(classes[headerColor], {
          [classes.expand]: expandRows,
        })}
        onClick={handleClick}
      >
        {linkId && redirect && <Navigate to={linkTo} />}
        {headerInfo}
      </div>
    </div>
  );
}

export default HeaderField;
