import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export const getProductInfoByBarcode = (params: { [name: string]: any }) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_PRODUCT_INFO",
    data: {
      barcode: params.barcode,
      module: params.module,
      scannedWithCamera: params.scannedWithCamera ? 1 : 0,
      departmentId: params.departmentId ? params.departmentId : undefined,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IProductInfoByRowNumber {
  clientDbId: number;
  locale: string;
  module: string;
  rowNumber: number;
  operationCode: string;
  departmentId?: string;
}

export const getProductInfoByRowNumber = (params: IProductInfoByRowNumber) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_PRODUCT_INFO",
    data: {
      module: params.module,
      rowNumber: params.rowNumber,
      operationCode: params.operationCode,
      departmentId: params.departmentId ? params.departmentId : undefined,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const getProductInfoCustom = (params: { [name: string]: any }) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_PRODUCT_INFO_CLIENT",
    data: {
      module: params.module,
      rowNumber: params.rowNumber,
      operationCode: params.operationCode,
      barcode: params.barcode,
      scannedWithCamera: params.scannedWithCamera ? 1 : 0,
      departmentId: params.departmentId ? params.departmentId : undefined,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};
