import React, { useCallback, useState } from "react";
import { Menu, Drawer, Alert, RowAdjustments } from "./index";
import {
  CheckOutlined,
  InfoOutlined,
  AppsOutlined,
  CreateOutlined,
  DeleteForeverOutlined,
} from "@mui/icons-material";
import {
  getProductInfoByRowNumber,
  getProductInfoByBarcode,
  getProductInfoCustom,
} from "../modules/api/productInfo";
import {
  manageIsFullyCollected,
  deleteRecord,
} from "../modules/api/docListsandDetails";
import { useSelector, useDispatch } from "react-redux";
import { setUserRefreshData } from "../modules/auth/slices/userSlice";
import {
  userClientDb,
  uiLanguage,
  refreshData,
  useExtraField1,
  useExtraField2,
  useExtraField3,
  useExtraField4,
  useExtraField5,
  userDepartmentId,
  userDeleteRow,
} from "../modules/auth/selectors/userSelector";
import { getErrorMessage } from "./form/formUtils";
import { useAuthorizationError, useLocalStorage } from "../hooks";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import * as modules from "../utils/modules";

interface IRowMenu {
  open: boolean;
  onClose: () => void;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  complete?: boolean;
  detailedInfo: {
    element1?: string;
    element2?: string;
    isFullyCollected?: number;
    quantityApp?: number;
    quantityDocument?: number;
    fraction?: number;
    rowNumber?: number;
    module?: string;
    operationCode?: string;
    operationType?: string;
    lot?: string;
    objectCode?: string;
    productCode?: string;
    unitCode?: string;
    barcode?: string;
    extraInfo1?: string;
    extraInfo2?: string;
    extraInfo3?: string;
    extraInfo4?: string;
    extraInfo5?: string;
    id?: string | number;
  };
}

function RowMenu(props: IRowMenu) {
  const { detailedInfo, open, onClose, anchorEl, complete } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const refresh = useSelector(refreshData);
  const [error, setError] = useState({ error: false, message: "", status: 0 });
  const [extraInfo, setExtraInfo] = useState({});
  const [logoutUser] = useAuthorizationError();
  const dispatch = useDispatch();
  const [translate] = useTranslation();
  const [openAdjDialog, setOpenAdjDialog] = useState(false);
  const [isCustomProc] = useLocalStorage("productCustomProc");
  const extra1 = useSelector(useExtraField1);
  const extra2 = useSelector(useExtraField2);
  const extra3 = useSelector(useExtraField3);
  const extra4 = useSelector(useExtraField4);
  const extra5 = useSelector(useExtraField5);
  const departmentId = useSelector(userDepartmentId);
  const canUserDeleteRow = useSelector(userDeleteRow);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleDetailedInfo = () => {
    let newData: { [name: string]: any } = { ...detailedInfo };
    delete newData.element1;
    delete newData.element2;
    delete newData.numberOfElements;
    delete newData.size;
    delete newData.pageNumber;
    delete newData.Row_number;
    delete newData.totalElements;

    const ex1 =
      extra1.type === "$B"
        ? JSON.stringify(newData.extraInfo1)
        : newData.extraInfo1;
    const ex2 =
      extra2.type === "$B"
        ? JSON.stringify(newData.extraInfo2)
        : newData.extraInfo2;
    const ex3 =
      extra3.type === "$B"
        ? JSON.stringify(newData.extraInfo3)
        : newData.extraInfo3;
    const ex4 =
      extra4.type === "$B"
        ? JSON.stringify(newData.extraInfo4)
        : newData.extraInfo4;
    const ex5 =
      extra5.type === "$B"
        ? JSON.stringify(newData.extraInfo5)
        : newData.extraInfo5;

    if (extra1.isUsed)
      newData = {
        ...newData,
        [extra1.name]: extra1.type === "$B" ? translate(ex1) : ex1,
      };

    if (extra2.isUsed)
      newData = {
        ...newData,
        [extra2.name]: extra2.type === "$B" ? translate(ex2) : ex2,
      };

    if (extra3.isUsed)
      newData = {
        ...newData,
        [extra3.name]: extra3.type === "$B" ? translate(ex3) : ex3,
      };

    if (extra4.isUsed)
      newData = {
        ...newData,
        [extra4.name]: extra4.type === "$B" ? translate(ex4) : ex4,
      };

    if (extra5.isUsed)
      newData = {
        ...newData,
        [extra5.name]: extra5.type === "$B" ? translate(ex5) : ex5,
      };

    delete newData.extraInfo1;
    delete newData.extraInfo2;
    delete newData.extraInfo3;
    delete newData.extraInfo4;
    delete newData.extraInfo5;

    setExtraInfo(newData);
    setOpenDrawer(true);
  };

  const handleInfoByRow = () => {
    getInfoByRow();
  };

  const handleCloseDrawer = () => {
    onClose();
    setOpenDrawer(false);
    setExtraInfo({});
  };

  const getInfoByRow = React.useCallback(
    () => {
      if (detailedInfo.rowNumber && detailedInfo.rowNumber > 0) {
        const params = {
          clientDbId: userDb ? userDb.id : 0,
          locale: locale,
          module: detailedInfo.module || "",
          rowNumber: detailedInfo.rowNumber || 0,
          operationCode: detailedInfo.operationCode || "",
          departmentId: departmentId,
        };

        const userProc = isCustomProc
          ? getProductInfoCustom
          : getProductInfoByRowNumber;

        userProc(params)
          .then(({ data }) => {
            if (data.length === 1) {
              const newInfo = { ...data[0] };
              setExtraInfo(newInfo);
              setOpenDrawer(true);
            }
          })
          .catch((reason: any) => {
            const errMessage = getErrorMessage(reason);
            setError({
              error: true,
              message: errMessage.message,
              status: errMessage.status,
            });
          });
      } else {
        const params = {
          clientDbId: userDb ? userDb.id : 0,
          locale: locale,
          barcode: detailedInfo.barcode || "",
          module: detailedInfo.module,
          departmentId: departmentId,
        };

        const userProc = isCustomProc
          ? getProductInfoCustom
          : getProductInfoByBarcode;

        userProc(params)
          .then(({ data }) => {
            if (data.length === 1) {
              const newInfo = { ...data[0] };
              setExtraInfo(newInfo);
              setOpenDrawer(true);
            }
          })
          .catch((reason: any) => {
            const errMessage = getErrorMessage(reason);
            setError({
              error: true,
              message: errMessage.message,
              status: errMessage.status,
            });
          });
      }
    }, // eslint-disable-next-line
    [getProductInfoByRowNumber, getProductInfoByBarcode, detailedInfo, userDb]
  );

  const handleClose = () => {
    logoutUser(error.status);

    if (error.status !== 401) {
      setError({ error: false, message: "", status: 0 });
      onClose();
    }
  };

  const markAsCollected = React.useCallback(
    () => {
      const params = {
        clientDbId: userDb ? userDb.id : 0,
        locale: locale,
        module: detailedInfo.module || "",
        rowNumber: detailedInfo.rowNumber || 0,
        operationCode: detailedInfo.operationCode || "",
        operationType: detailedInfo.operationType || "",
        lot: typeof detailedInfo.lot !== "undefined" ? detailedInfo.lot : "",
        objectCode:
          typeof detailedInfo.objectCode !== "undefined"
            ? detailedInfo.objectCode
            : "",
        productCode:
          typeof detailedInfo.productCode !== "undefined"
            ? detailedInfo.productCode
            : "",
        isFullyCollected:
          detailedInfo.isFullyCollected === 1 ||
          (typeof detailedInfo.isFullyCollected === "string" &&
            detailedInfo.isFullyCollected === "1")
            ? 0
            : 1,
        unitCode: detailedInfo.unitCode || "",
        barcode: detailedInfo.barcode || "",
      };

      manageIsFullyCollected(params)
        .then(() => {
          dispatch(setUserRefreshData(!refresh));
        })
        .catch((reason: any) => {
          const errMessage = getErrorMessage(reason);
          setError({
            error: true,
            message: errMessage.message,
            status: errMessage.status,
          });
        });
    }, // eslint-disable-next-line
    [manageIsFullyCollected, detailedInfo, userDb]
  );

  const handleOpenRowAdj = () => {
    setOpenAdjDialog(true);
  };

  const handleCloseRowAdj = () => {
    setOpenAdjDialog(false);
    onClose();
  };

  const showError = (
    <Alert
      isOpen={error.error}
      message={error.message}
      iconColor="error"
      onClose={handleClose}
    />
  );

  const handleCloseConfirmation = useCallback(() => {
    onClose();
    setOpenConfirmation(false);
  }, [onClose, setOpenConfirmation]);

  const handleDeleteConfirmation = useCallback(() => {
    const params = {
      clientDbId: userDb ? userDb.id : 0,
      locale: locale,
      module: detailedInfo.module || "",
      rowNumber: detailedInfo.rowNumber || 0,
      operationCode: detailedInfo.operationCode || "",
      operationType: detailedInfo.operationType || "",
      id: detailedInfo.id || "",
    };

    deleteRecord(params)
      .then(() => {
        setOpenConfirmation(false);
        dispatch(setUserRefreshData(!refresh));
      })
      .catch((reason: any) => {
        setOpenConfirmation(false);
        const errMessage = getErrorMessage(reason);
        setError({
          error: true,
          message: errMessage.message,
          status: errMessage.status,
        });
      });
  }, [deleteRecord, detailedInfo, userDb]);

  const deleteRowConfirmation = (
    <Alert
      isOpen={openConfirmation}
      message={t("deleteRecord", "Ar tikrai norite išmesti įrašą?")}
      iconColor="warning"
      onClose={handleCloseConfirmation}
      onConfirm={handleDeleteConfirmation}
      isConfirmationButton
    />
  );

  const handleRowDeletion = useCallback(() => {
    setOpenConfirmation(true);
  }, [setOpenConfirmation]);

  const menuItems = React.useMemo(
    () => {
      const options = [
        {
          name: translate("rowInformation", "Informacija apie eilutę"),
          icon: <InfoOutlined />,
          onClick: handleDetailedInfo,
        },
        {
          name: translate("informationByBarcode", "Informacija pagal barkodą"),
          icon: <AppsOutlined />,
          onClick: handleInfoByRow,
        },
      ];

      if (!complete) {
        options.push({
          name: translate(
            "markAsCollected",
            "Pažymėti kaip atrinktą / neatrinktą"
          ),
          icon: <CheckOutlined />,
          onClick: markAsCollected,
        });
        options.push({
          name: translate("rowAdjustment", "Eilutės koregavimas"),
          icon: <CreateOutlined />,
          onClick: handleOpenRowAdj,
        });

        if (
          canUserDeleteRow &&
          detailedInfo &&
          !(
            detailedInfo.operationType === "PAJ" &&
            detailedInfo.module === modules.MODULE_INTERNAL
          )
        )
          options.push({
            name: translate("delete", "Ištrinti"),
            icon: <DeleteForeverOutlined />,
            onClick: handleRowDeletion,
          });
      }

      return options;
    }, // eslint-disable-next-line
    [complete, canUserDeleteRow, detailedInfo]
  );

  return (
    <div>
      {error.error && showError}

      {deleteRowConfirmation}

      {openDrawer && (
        <Drawer
          isOpen={openDrawer}
          onClose={handleCloseDrawer}
          data={extraInfo}
        />
      )}

      {openAdjDialog && (
        <RowAdjustments
          open={openAdjDialog}
          onClose={handleCloseRowAdj}
          rowData={detailedInfo}
        />
      )}

      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        data={menuItems}
      />
    </div>
  );
}

export default RowMenu;
