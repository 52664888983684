import React from "react";
import LoginForm from "./components/LoginForm";
import { Logo, BrandPattern } from "../../logo";
import { LanguageSwitch } from "../../components";
import { LoginLayout } from "../../layouts";
import { useAuthStyles } from "./css";

function Auth() {
  const { classes } = useAuthStyles();

  return (
    <LoginLayout>
      <div className={classes.container}>
        <div className={classes.root}>
          <div className={classes.language}>
            <LanguageSwitch />
          </div>
          <div className={classes.loginDiv}>
            <div className={classes.logo}>
              <Logo width="100" />
            </div>
            <div className={classes.logo}>
              <LoginForm />
            </div>
          </div>
        </div>
        <div className={classes.bottom}>
          <BrandPattern width="100%" />
        </div>
      </div>
    </LoginLayout>
  );
}

export default React.memo(Auth);
