import { makeStyles } from "tss-react/mui";

const useSpinnerStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: "#fff",
  },
}));

export default useSpinnerStyles;
