import { makeStyles } from "tss-react/mui";

const useProductInfoFormStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: "#fff",
  },
  image: {
    objectFit: "contain",
    height: "30vh",
  },
  zoomedImage: {
    objectFit: "contain",
  },
}));

export default useProductInfoFormStyles;
