import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import {
  ArrowBackOutlined,
  CloseOutlined,
  Notes,
  RefreshOutlined,
} from "@mui/icons-material";
import { useNavigationBarStyles } from "./css";
import * as routes from "../app/routes";
import { setUserRefreshData } from "../modules/auth/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { refreshData } from "../modules/auth/selectors/userSelector";

interface INavigationBar {
  moduleName?: JSX.Element | string;
  modulePath?: string;
  moduleIcon?: JSX.Element;
  closeButton?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isDocumentList?: boolean;
  btnIcon?: React.ReactNode;
  btnText?: string;
  btnOnClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  btnLink?: string;
}

function NavigationBar(props: INavigationBar) {
  const {
    moduleName,
    modulePath,
    moduleIcon,
    closeButton,
    btnIcon,
    btnText,
    btnOnClick,
    btnLink,
    isDocumentList,
  } = props;
  const { classes, cx } = useNavigationBarStyles();
  const refreshState = useSelector(refreshData);
  const [refresh, setRefresh] = useState(refreshState);
  const dispatch = useDispatch();
  const isButton = typeof btnText !== "undefined";

  const handleRefresh = React.useCallback(
    () => {
      const shouldRefresh = refresh;
      dispatch(setUserRefreshData(!shouldRefresh));
      setRefresh(!shouldRefresh);
    },
    // eslint-disable-next-line
    [refresh]
  );

  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        classes={{ root: classes.root }}
      >
        <Toolbar>
          <div className={classes.gridContainer}>
            <div className={cx({ [classes.leftItem]: isButton })}>
              {/* Document List, possibility to refresh list*/}
              {modulePath && isDocumentList && (
                <div onClick={handleRefresh}>
                  <Typography variant="h2">
                    <div className={classes.textAndIcon}>
                      <RefreshOutlined fontSize="small" />
                      {moduleName ? moduleName : "MSCAN"}
                    </div>
                  </Typography>
                </div>
              )}

              {/* Detailed information, possibility to go back */}
              {modulePath && !isDocumentList && (
                <Link
                  to={modulePath}
                  className={cx(classes.link, classes.icon)}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(-1);
                  }}
                >
                  <Typography variant="h2">
                    <div className={classes.textAndIcon}>
                      <ArrowBackOutlined fontSize="small" />
                      {moduleName ? moduleName : "MSCAN"}
                    </div>
                  </Typography>
                </Link>
              )}

              {/* Options list*/}
              {!modulePath && (
                <Typography variant="h2">
                  <div className={classes.textAndIcon}>
                    {moduleIcon}
                    {moduleName ? moduleName : "MSCAN"}
                  </div>
                </Typography>
              )}
            </div>

            <div className={cx({ [classes.rightItem]: isButton })}>
              <div>
                {btnLink ? (
                  <Link to={btnLink ? btnLink : "/"} className={classes.link}>
                    <Button color="primary" startIcon={btnIcon}>
                      {btnText}
                    </Button>
                  </Link>
                ) : (
                  ""
                )}

                {btnOnClick ? (
                  <Button
                    color="primary"
                    startIcon={btnIcon}
                    onClick={btnOnClick}
                  >
                    {btnText}
                  </Button>
                ) : (
                  ""
                )}
              </div>

              <div>
                {closeButton ? (
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="medium"
                    onClick={closeButton}
                  >
                    <CloseOutlined />
                  </IconButton>
                ) : (
                  <Link
                    to={routes.ROUTE_OPTIONS.path}
                    state={{ from: modulePath }}
                    className={classes.link}
                  >
                    {/* <Link to={location} className={classes.link}>*/}
                    <IconButton className={classes.icon} size="medium">
                      <Notes />
                    </IconButton>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default React.memo(NavigationBar);
