import React from "react";
import { LoginLayout } from "../../layouts";
import OptionsList from "./components/OptionsList";

function Options() {
  return (
    <LoginLayout>
      <OptionsList />
    </LoginLayout>
  );
}

export default React.memo(Options);
