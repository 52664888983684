import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import "normalize.css";
import App from "./app/App";
import store from "./app/store/store";
import "./app/i18n";
import { unregister } from "./serviceWorker";

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("container") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
