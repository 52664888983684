import React from "react";
import { MenuItem } from "@mui/material";
import TextField from "./TextField";
import { useSelectStyles } from "./css";

export interface ISelect {
  options: { value?: string | number; label?: string }[] | undefined;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  fullWidth?: boolean;
  otherColor?: "white" | "grey";
  endAdornment?: JSX.Element;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Select = React.forwardRef<any, ISelect>((props, ref) => {
  const {
    onChange,
    options = [],
    value,
    label,
    fullWidth = false,
    otherColor,
    endAdornment,
    required = false,
    disabled = false,
    ...rest
  } = props;
  const { classes } = useSelectStyles();

  return (
    <TextField
      select
      value={value}
      onChange={onChange}
      label={label}
      fullWidth={fullWidth}
      otherColor={otherColor}
      endAdornment={endAdornment}
      required={required}
      disabled={disabled}
      ref={ref}
      {...rest}
    >
      {options.map((item) => (
        <MenuItem
          key={item.value}
          value={item.value}
          classes={{ root: classes.root }}
        >
          {item.label ? item.label : item.value}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default Select;
