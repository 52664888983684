import { makeStyles } from "tss-react/mui";

const useOutlinedAutocompleteStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      color: "#D5D7DB",
    },
    "& .MuiOutlinedInput-root": {
      padding: "0",
      "& fieldset": {
        borderColor: theme.palette.grey.A200,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },

    "& .MuiAutocomplete-inputRoot": {
      "& .MuiAutocomplete-input": {
        paddingTop: "10.5px",
        paddingBottom: "10.5px",
      },
      "&[class*='MuiOutlinedInput-root']": {
        paddingRight: "14px",
      },
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  greyBorder: {
    color: theme.palette.common.black,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D5D7DB",
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.black,
    },
  },
  renderOpDiv: {
    maxWidth: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    paddingLeft: "14px",
  },
}));

export default useOutlinedAutocompleteStyles;
