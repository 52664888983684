import { makeStyles } from "tss-react/mui";

const useCheckBoxStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  checkbox: {
    color: theme.palette.grey.A200,
  },
  grid: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
}));

export default useCheckBoxStyles;
