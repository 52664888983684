import { makeStyles } from "tss-react/mui";

const useAlertStyles = makeStyles()((theme) => ({
  iconWarning: {
    color: theme.palette.warning.main,
    fontSize: "5rem",
  },
  iconError: {
    color: theme.palette.error.main,
  },
  iconSuccess: {
    color: theme.palette.primary.main,
  },
  paper: {
    width: "90%",
    margin: "10px auto",
  },
  typography: {
    textAlign: "center",
  },
  root: {
    zIndex: 1400,
  },
}));

export default useAlertStyles;
