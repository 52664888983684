import React from "react";
import { Fab as MuiFab } from "@mui/material";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import { useFabStyles } from "./css";

interface IFab {
  size?: "small" | "large" | "medium";
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function Fab(props: IFab) {
  const { size = "small", icon, onClick } = props;
  const { classes } = useFabStyles();

  return (
    <MuiFab
      size={size}
      onClick={onClick}
      className={classes.fab}
      component="div"
    >
      {icon ? icon : <FlipCameraAndroidIcon className={classes.icon} />}
    </MuiFab>
  );
}

export default Fab;
