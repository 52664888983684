import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUiLanguage } from "../modules/auth/slices/userSlice";
import { useTranslation } from "react-i18next";
import Select from "./Select";

export default function LanguageSwitch() {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState("lt");
  const [translate] = useTranslation();
  const languages = process.env.REACT_APP_SUPPORTED_LANGUAGES;
  const languagesArray = languages ? languages.split(",") : ["lt"];
  const options = languagesArray.map((language) => ({
    value: language,
    label: translate(language),
  }));

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const selectedValue = event.target.value;
    dispatch(setUiLanguage(selectedValue));
    setLanguage(selectedValue);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={language}
      label={translate("language", "Kalba")}
      fullWidth={false}
    />
  );
}
