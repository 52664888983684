import React from "react";
import { useForm, useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { barcodeScanner } from "./formUtils";
import { userSeparator } from "../../modules/auth/selectors/userSelector";
import { useSelector } from "react-redux";

interface IWhenFieldChanges {
  field: string;
  fieldAmount: string;
  fieldLot?: string;
  fieldObject?: string;
}

const WhenBarcodeChanges = (props: IWhenFieldChanges) => {
  const { field, fieldAmount, fieldLot, fieldObject } = props;
  const { values } = useFormState();
  const form = useForm();
  const separator = useSelector(userSeparator);

  return (
    <OnChange name={field}>
      {() => {
        const barcode = barcodeScanner(values[field], separator);
        const amount = barcode.amount;
        const lot = barcode.serija;
        const object = barcode.kodasOs;
        let isObjectEntered = false;
        let isLotEntered = false;

        if (values[field] && values[field].indexOf(separator) > -1) {
          const combinedValues = values[field].split(separator);

          if (typeof combinedValues[1] !== "undefined") isObjectEntered = true;
          if (typeof combinedValues[2] !== "undefined") isLotEntered = true;
        }

        if (fieldAmount && amount !== 0) {
          form.change(fieldAmount, amount);
        }

        if (fieldObject && isObjectEntered) {
          form.change(fieldObject, object);
        }

        if (fieldLot && isLotEntered) {
          form.change(fieldLot, lot);
        }
      }}
    </OnChange>
  );
};

export default WhenBarcodeChanges;
