import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch, AppThunk } from "../../../app/store/store";
import {
  deleteTokenExpirationAndRememberDate,
  saveTokenExpirationDate,
} from "../../../utils/authToken";
import { login, logout, profile } from "../../api/auth";
import {
  clearUserDetails,
  setUserDetails,
  setUserExtraFieldsArray,
} from "./userSlice";

interface IAuthState {
  loginState: "idle" | "loading" | "error";
  error: string | undefined;
  timeout: number;
}

const initialState: IAuthState = {
  loginState: "idle",
  error: undefined,
  timeout: 0, // indicates time when session was created or renewed
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logInError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loginState = "error";
    },
    setLoginState(state, action: PayloadAction<"idle" | "loading" | "error">) {
      state.loginState = action.payload;
    },
    extendSuccess(state, action: PayloadAction<number>) {
      state.timeout = action.payload;
    },
    extendError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const doLogIn =
  (
    username: string,
    password: string,
    rememberMe: boolean,
    device: string = navigator.userAgent
  ): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setLoginState("loading"));

    login(username, password, rememberMe, device)
      .then(async (response) => {
        const tokenExpirationDate = response.headers['x-token-expires'];
        saveTokenExpirationDate(
          new Date(tokenExpirationDate).toISOString(),
          rememberMe
        );

        const userProfileResponse = await profile();

        dispatch(setUserDetails(userProfileResponse.data));

        dispatch(authSlice.actions.setLoginState("idle"));
      })
      .catch((error) => {
        dispatch(authSlice.actions.setLoginState("error"));
        dispatch(authSlice.actions.logInError(error.message));
      });
  };

export const logOut = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(authSlice.actions.setLoginState("loading"));
    deleteTokenExpirationAndRememberDate();
    dispatch(clearUserDetails());
    dispatch(setUserExtraFieldsArray([]));
    dispatch(authSlice.actions.setLoginState("idle"));
    dispatch(authSlice.actions.logInError(""));
    localStorage.removeItem("lastLanguage");
    logout();
  } catch (err) {
    console.error(err);
  }
};

export default authSlice.reducer;
