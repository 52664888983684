import React from "react";
import { Paper, Divider, Grid } from "@mui/material";
import { useIconFieldStyles } from "./css";

interface IIconField {
  title: React.ReactNode;
  icon: React.ReactNode;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined
  ) => void;
  iconOnRight?: React.ReactNode;
  disabled?: boolean;
  greenText?: boolean;
}

function IconField(props: IIconField) {
  const {
    icon,
    title,
    onClick,
    iconOnRight,
    disabled,
    greenText = false,
  } = props;
  const { classes, cx } = useIconFieldStyles();

  return (
    <div>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item container direction="row">
          <Paper
            elevation={0}
            classes={{ root: classes.root }}
            square
            onClick={onClick}
          >
            <Grid
              item
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              wrap="nowrap"
              className={cx({
                [classes.disabled]: disabled === true,
                [classes.greenText]: greenText,
              })}
            >
              <Grid
                item
                container
                spacing={1}
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>{icon}</Grid>
                <Grid item>{title}</Grid>
              </Grid>

              <Grid item>{iconOnRight}</Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default IconField;
