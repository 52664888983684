import React, { ReactNode } from "react";
import { useLoginLayoutStyles } from "./css";

interface ILoginLayout {
  children: ReactNode;
}

export default function LoginLayout(props: ILoginLayout) {
  const { children } = props;
  const { classes } = useLoginLayoutStyles();

  return (
    <div className={classes.extralayout}>
      <div className={classes.layout}>{children}</div>
    </div>
  );
}
