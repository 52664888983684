import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import i18n from "../../../app/i18n";
import {
  isJustRefreshed,
  isTokenExpired,
  onSuccessedRequest,
  refreshToken,
} from "../../../utils/authToken";

const API_URI =
  process.env.REACT_APP_API === undefined ? "" : process.env.REACT_APP_API;
const API_TIMEOUT =
  process.env.REACT_APP_API_TIMEOUT_MS === undefined
    ? 10000
    : parseInt(process.env.REACT_APP_API_TIMEOUT_MS);

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URI,
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
};

const client: AxiosInstance = axios.create(axiosConfig);

let initialPageLoad = true;

client.interceptors.request.use(async function (request) {
  if (request?.url?.endsWith?.("/auth/login")) {
    return request;
  }

  if (request?.url?.endsWith?.("/auth/refresh")) {
    return request;
  }

  if (request?.url?.endsWith?.("/auth/logout")) {
    return request;
  }

  const needTokenRefresh = isTokenExpired() || initialPageLoad;
  if (needTokenRefresh) {
    try {
      await refreshToken();
      initialPageLoad = false;
      return request;
    } catch (err) {
      console.error(err);
      const error = new Error("Failed to refresh access token before request");
      (error as any).isAxiosError = true;
      (error as any).response = {
        status: 401,
      };
      throw error;
    }
  }

  return request;
});

client.interceptors.response.use(
  function (response) {
    onSuccessedRequest();
    return response;
  },
  async function (err) {
    if (err.isAxiosError && err.response && err.response.status === 401) {
      if (err.request?.responseURL?.endsWith?.("/auth/login")) {
        return Promise.reject(err);
      }

      if (err.request?.responseURL?.endsWith?.("/auth/refresh")) {
        return Promise.reject(err);
      }

      if (err.request?.responseURL?.endsWith?.("/auth/logout")) {
        return Promise.reject(err);
      }

      try {
        if (isJustRefreshed()) {
          return Promise.reject(err);
        }
        const result = await refreshToken();
        if (result) {
          return client.request(err.config);
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}

      err.message = i18n.t("Session expired. Please login again");
      return Promise.reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

export { client };
