import i18next from "i18next";

const isEmpty = (value: any) => value == null || value === "";

export const isNumber = (value: any) => {
  let isNum;
  let insertedValue = value;
  let lengthToPoint = 0;
  let integerPart = "";

  if (typeof value === "string") {
    insertedValue = value.replace(",", ".");
  } else if (value) {
    insertedValue = JSON.stringify(value).replace(",", ".");
  }

  if (isNaN(insertedValue))
    isNum = i18next.t("enterNumber", "Įveskite skaičių!");

  if (insertedValue && insertedValue.charAt(0) === "-")
    insertedValue = insertedValue.substring(1);

  if (insertedValue && insertedValue.length > 7) {
    lengthToPoint = insertedValue.indexOf(".");

    if (lengthToPoint === -1) lengthToPoint = insertedValue.length;
    integerPart = insertedValue.slice(0, lengthToPoint);
  }

  if (integerPart.length > 7)
    isNum = i18next.t("tooLong", "Per didelis skaičius!");

  return isNum;
};

export const isNumberOrEmpty = (value: any) => {
  let isNum;
  let insertedValue = value;

  if (typeof value === "string") {
    insertedValue = value.replace(",", ".");
  } else if (value) {
    insertedValue = JSON.stringify(value).replace(",", ".");
  }

  if (isNaN(insertedValue) && !isEmpty(insertedValue))
    isNum = i18next.t("enterNumber", "Įveskite skaičių!");

  return isNum;
};

export const isDateValid = (value: any) => {
  let isDate;
  const insertedValue = value;

  if (insertedValue !== "" && insertedValue > "3999-01-01")
    isDate = i18next.t(
      "maximalDate",
      "Nurodyta data didesnė nei maksimaliai leistina"
    );

  if (insertedValue !== "" && insertedValue < "1900-01-01")
    isDate = i18next.t(
      "minimalDate",
      "Nurodyta data mažesnė nei minimaliai leistina"
    );

  return isDate;
};

export const required = (value: any) =>
  !isEmpty(value) ? undefined : "Privaloma!";
