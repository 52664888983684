import React from "react";
import { BaseLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import CompletionDetails from "./components/CompletionDetails";
import { useParams } from "react-router-dom";
import { modulePath } from "../../utils/modules";

function Completion(props: any) {
  const [translate] = useTranslation();
  const parameters = useParams<{ module: string; id: string }>();
  const module = parameters.module || "";
  const operationId = parameters.id;
  const path = modulePath(module) + "/" + operationId;

  return (
    <BaseLayout
      moduleName={translate("completion", "Užbaigimas")}
      modulePath={path}
    >
      <CompletionDetails {...props} />
    </BaseLayout>
  );
}

export default Completion;
