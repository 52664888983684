import React from "react";
import { useInfoFieldStyles } from "./css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { userExpandRows } from "../../modules/auth/selectors/userSelector";

interface ICellRenderer {
  data: {
    property: string;
    value: string;
  };
}

function InfoField(props: ICellRenderer) {
  const { data } = props;
  const [translate] = useTranslation();
  const { classes, cx } = useInfoFieldStyles();
  const expandRows = useSelector(userExpandRows);

  return (
    <React.Fragment>
      {data ? (
        <div>
          <div
            className={cx(classes.container, {
              [classes.limitedHeight]: !expandRows,
            })}
          >
            <div
              className={cx(classes.left, {
                [classes.limitedHeight]: !expandRows,
              })}
            >
              <div className={cx({ [classes.noWrap]: !expandRows })}>
                {data &&
                  translate(
                    data.property.toLocaleLowerCase(),
                    data.property.toLocaleLowerCase()
                  )}
              </div>
            </div>
            <div
              className={cx(classes.right, {
                [classes.limitedHeight]: !expandRows,
              })}
            >
              <div className={cx({ [classes.noWrap]: !expandRows })}>
                {data && data.value}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default InfoField;
