import { makeStyles } from "tss-react/mui";

const useTextFieldStyles = makeStyles()((theme) => ({
  input: {
    color: theme.palette.common.white,
  },
  label: {
    color: theme.palette.grey.A200,
  },
  root: {
    "& ::-ms-reveal": {
      display: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey.A200,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiSelect-icon": {
      color: theme.palette.common.white,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: theme.palette.grey.A400,
    },
    "& .Mui-disabled": {
      color: theme.palette.grey.A400,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey.A400,
      },
    },
  },
  white: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    "&:-webkit-autofill": {
      WebkitTextFillColor: theme.palette.common.black,
      "&:hover": {
        WebkitTextFillColor: theme.palette.common.black,
      },
      "&:focus": {
        WebkitTextFillColor: theme.palette.common.black,
      },
    },
  },
  borders: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderTop: "1px solid #D7D8DD",
    },
  },
  greyBorder: {
    color: theme.palette.common.black,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D5D7DB",
      },
    },
    "& .MuiSelect-select": {
      "&:focus": {
        backgroundColor: theme.palette.common.white,
      },
    },
    "& .MuiSelect-icon": {
      color: theme.palette.common.black,
    },
  },
  greyLabel: {
    color: "#D5D7DB",
  },
}));

export default useTextFieldStyles;
