import { makeStyles } from "tss-react/mui";

const useOptionsListStyles = makeStyles()((theme) => ({
  divider: {
    backgroundColor: theme.palette.common.white,
    height: "1px",
    opacity: "0.15",
  },
  grid: {
    minHeight: "100Vh",
  },
  bottomGrid: {
    height: "100%",
  },
  notAllowed: {
    pointerEvents: "none",
    textDecoration: "none",
  },
  link: {
    textDecoration: "none",
  },
}));

export default useOptionsListStyles;
