import i18next from "i18next";

export function showError(meta = {} as { [key: string]: any }) {
  const { submitError, error, touched, dirtySinceLastSubmit } = meta;
  return ((submitError && !dirtySinceLastSubmit) || error) && touched;
}

export function barcodeScanner(data: string, separator?: string) {
  let amount = 0;
  let barcode = data;
  let serija = "";
  let kodasOs = "";
  const barcodeSeparator = separator || ";";

  if (data && data.indexOf("*") > -1) {
    const pos = data.indexOf("*");
    const qty = data.substring(0, pos).replace(",", ".");
    amount = parseFloat(qty);
    barcode = data.substring(pos + 1);
  }

  if (data && data.indexOf(barcodeSeparator) > -1) {
    const dataArray = barcode.split(barcodeSeparator);

    if (dataArray[1]) kodasOs = dataArray[1];
    if (dataArray[2]) serija = dataArray[2];

    barcode = dataArray[0];
  }

  return { barcode, amount, serija, kodasOs };
}

export function getErrorMessage(err: any) {
  let detailedMessages = "";
  const errorStatus =
    err.response && err.response.status ? err.response.status : 0;
  let message =
    err.response && err.response.data
      ? err.response.data.errorMessage
      : err.message;

  if (!message && err.response && err.response.data) {
    message = `${err.message}. ${err.response.data.error}. ${err.response.data.message}`;
  }

  if (!message) {
    message = err.message;
  }

  if (err.response && err.response.data) {
    const messageArray = err.response.data.errors
      ? err.response.data.errors
      : [];

    for (let i = 0; i < messageArray.length; i++) {
      if (messageArray[i].dataErrors && messageArray[i].dataErrors.length > 0) {
        for (let j = 0; j < messageArray[i].dataErrors.length; j++) {
          detailedMessages += `${messageArray[i].dataErrors[j].code} ${messageArray[i].dataErrors[j].message}. \n`;
        }
      }
    }
  }

  if (errorStatus === 401 && message.toLowerCase().includes("unauthorized")) {
    return {
      status: errorStatus,
      message: i18next.t(
        "unauthorized",
        "Vartotojas neautorizuotas. Prisijunkite iš naujo!"
      ),
    };
  }

  return { status: errorStatus, message: `${message} ${detailedMessages}` };
}

export function getWarningMessage(warning: any) {
  let warningMessage = "";

  for (let i = 0; i < warning.length; i++) {
    warningMessage += warning[i].warningMessage;
  }

  return warningMessage;
}
