import { makeStyles } from "tss-react/mui";

const useInfoFieldStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 15px",
    lineHeight: "23px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  left: {
    width: "50%",
    overflow: "hidden",
    overflowWrap: "break-word",
  },
  right: {
    textAlign: "right",
    width: "50%",
    flexWrap: "nowrap",
    color: theme.palette.grey.A700,
    overflowWrap: "break-word",
  },
  limitedHeight: {
    lineHeight: "27px",
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export default useInfoFieldStyles;
