import { makeStyles } from "tss-react/mui";

const useIconFieldStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: "inherit",
    width: "90%",
    margin: `${theme.spacing(1)} auto`,
    color: theme.palette.common.white,
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    height: "1px",
    opacity: "0.15",
  },
  disabled: {
    color: theme.palette.grey.A200,
  },
  greenText: {
    color: theme.palette.primary.main,
  },
}));

export default useIconFieldStyles;
