import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { useSpinnerStyles } from "./css";

interface ISpinner {
  open: boolean;
}

function Spinner(props: ISpinner) {
  const { open } = props;
  const { classes } = useSpinnerStyles();

  return (
    <Backdrop className={classes.backdrop} open={open} invisible>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

export default Spinner;
