import { makeStyles } from "tss-react/mui";

const useCompletionStyles = makeStyles()((theme) => ({
  mainContainer: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "column",
  },
  container: {
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  autocomplete: {
    "& .MuiFormLabel-root": {
      color: "#D5D7DB",
    },
    "& .MuiOutlinedInput-root": {
      padding: "0",
      "& fieldset": {
        borderColor: theme.palette.grey.A200,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },

    "& .MuiAutocomplete-inputRoot": {
      "& .MuiAutocomplete-input": {
        paddingTop: "10.5px",
        paddingBottom: "10.5px",
      },
      "&[class*='MuiOutlinedInput-root']": {
        paddingRight: "14px !important",
      },
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
}));

export default useCompletionStyles;
