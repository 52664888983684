import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./appRoutes";
import AppTheme from "./AppTheme";
import { LoaderProvider } from "../components";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DayjsUtils from "@date-io/dayjs";
// import "dayjs/locale/lt";
// import dayjs from "dayjs";
import ltLocale from "date-fns/locale/lt";

// dayjs.locale("lt");

export default function App() {
  return (
    <BrowserRouter>
      <AppTheme>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ltLocale}>
          <LoaderProvider>
            <AppRoutes />
          </LoaderProvider>
        </LocalizationProvider>
      </AppTheme>
    </BrowserRouter>
  );
}
