import React, { useState } from "react";
import {
  OutlinedAutocomplete,
  OutlinedTextField,
  OutlinedSelectField,
  OutlinedCheckbox,
  OutlinedDatePicker,
} from "./index";
import { isNumberOrEmpty, isDateValid } from "../../utils/validate";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IExtraFields {
  fieldsList?: { [name: string]: any }[];
  buttonRef?: React.MutableRefObject<HTMLButtonElement | undefined>;
  noteRef?: React.MutableRefObject<HTMLInputElement | undefined>;
}

function ExtraFields(props: IExtraFields) {
  const { fieldsList, buttonRef, noteRef: parentNoteRef } = props;
  const [translate] = useTranslation();
  const [noteList, setNoteList] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const noteref = React.useRef<HTMLInputElement>();
  const extra1ref = React.useRef<HTMLInputElement>();
  const extra2ref = React.useRef<HTMLInputElement>();
  const extra3ref = React.useRef<HTMLInputElement>();
  const extra4ref = React.useRef<HTMLInputElement>();
  const extra5ref = React.useRef<HTMLInputElement>();

  const [field1, setField1] = useState<{
    show: boolean;
    component?: JSX.Element;
    type?: string;
  }>({
    show: false,
  });

  const [field2, setField2] = useState<{
    show: boolean;
    component?: JSX.Element;
    type?: string;
  }>({
    show: false,
  });

  const [field3, setField3] = useState<{
    show: boolean;
    component?: JSX.Element;
    type?: string;
  }>({
    show: false,
  });

  const [field4, setField4] = useState<{
    show: boolean;
    component?: JSX.Element;
    type?: string;
  }>({
    show: false,
  });

  const [field5, setField5] = useState<{
    show: boolean;
    component?: JSX.Element;
    type?: string;
  }>({
    show: false,
  });

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.select();
  };

  const componentsList = React.useCallback(
    (type: string, params: any) => {
      switch (type.toLocaleUpperCase()) {
        case "$N":
          return (
            <OutlinedTextField
              {...params}
              otherColor="grey"
              validate={isNumberOrEmpty}
              autoComplete="off"
              inputMode="decimal"
              onFocus={handleFocus}
              onKeyPress={handleKeyPress}
            />
          );
        case "$C":
          return (
            <OutlinedTextField
              {...params}
              otherColor="grey"
              autoComplete="off"
              onFocus={handleFocus}
              onKeyPress={handleKeyPress}
            />
          );
        case "$B":
          return (
            <OutlinedCheckbox
              {...params}
              labelColor="black"
              onFocus={handleFocus}
              onKeyPress={handleKeyPress}
            />
          );
        case "SELECT":
          return (
            <OutlinedSelectField
              {...params}
              otherColor="grey"
              fullWidth
              onKeyPress={handleKeyPress}
            />
          );
        case "LIST":
          return (
            <OutlinedAutocomplete
              {...params}
              otherColor="grey"
              onKeyPress={handleKeyPress}
              inputValue
            />
          );
        case "$D":
          return (
            <OutlinedDatePicker
              {...params}
              autoOk
              clearable
              autoComplete="off"
              otherColor="grey"
              onKeyPress={handleKeyPress}
              validate={isDateValid}
            />
          );
      }
    }, // eslint-disable-next-line
    []
  );

  React.useEffect(
    () => {
      if (noteref.current) noteref.current.focus();
    }, // eslint-disable-next-line
    [noteref]
  );

  React.useEffect(() => {
    const allFields = fieldsList
      ? fieldsList.filter(
          (field: any) =>
            field.parameter.toUpperCase() === "PAP1" ||
            field.parameter.toUpperCase() === "PAP2" ||
            field.parameter.toUpperCase() === "PAP3" ||
            field.parameter.toUpperCase() === "PAP4" ||
            field.parameter.toUpperCase() === "PAP5" ||
            field.parameter.toUpperCase() === "NOTES"
        )
      : [];

    if (allFields.length === 0) return;

    //NOTES
    const notes = allFields.filter(
      (field: any) => field.parameter.toUpperCase() === "NOTES"
    );

    if (notes.length > 0) {
      const options = notes.map((note: any) => ({
        value: note.code,
        label: note.code,
      }));
      options.unshift({ label: translate("empty", "(tuščia)"), value: "" });
      setNoteList(options);
    }

    // First field
    const fd1 = allFields.filter(
      (field: any) => field.parameter.toUpperCase() === "PAP1"
    );

    if (fd1.length > 0) {
      const name = "extraInfo1";
      const label = fd1[0].fieldName;
      const type = fd1[0].type;
      const options =
        type === "SELECT" || type === "LIST"
          ? fd1.map((option: any) => ({
              label: type !== "SELECT" ? option.name : option.code,
              value:
                typeof option.code !== "string"
                  ? JSON.stringify(option.code)
                  : option.code,
            }))
          : undefined;

      if (type === "SELECT" && options)
        options.unshift({ label: translate("empty", "(tuščia)"), value: "" });

      const params = { name, label, options, ref: extra1ref };

      setField1({
        show: true,
        type: type,
        component: componentsList(type, params),
      });
    }

    // Second field
    const fd2 = allFields.filter(
      (field: any) => field.parameter.toUpperCase() === "PAP2"
    );

    if (fd2.length > 0) {
      const name = "extraInfo2";
      const label = fd2[0].fieldName;
      const type = fd2[0].type;
      const options =
        type === "SELECT" || type === "LIST"
          ? fd2.map((option: any) => ({
              label: type !== "SELECT" ? option.name : option.code,
              value:
                typeof option.code !== "string"
                  ? JSON.stringify(option.code)
                  : option.code,
            }))
          : undefined;

      if (type === "SELECT" && options)
        options.unshift({ label: translate("empty", "(tuščia)"), value: "" });

      const params = { name, label, options, ref: extra2ref };

      setField2({
        show: true,
        type: type,
        component: componentsList(type, params),
      });
    }

    //Third field
    const fd3 = allFields.filter(
      (field: any) => field.parameter.toUpperCase() === "PAP3"
    );

    if (fd3.length > 0) {
      const name = "extraInfo3";
      const label = fd3[0].fieldName;
      const type = fd3[0].type;
      const options =
        type === "SELECT" || type === "LIST"
          ? fd3.map((option: any) => ({
              label: type !== "SELECT" ? option.name : option.code,
              value:
                typeof option.code !== "string"
                  ? JSON.stringify(option.code)
                  : option.code,
            }))
          : undefined;

      if (type === "SELECT" && options)
        options.unshift({ label: translate("empty", "(tuščia)"), value: "" });

      const params = { name, label, options, ref: extra3ref };

      setField3({
        show: true,
        type: type,
        component: componentsList(type, params),
      });
    }

    //Fourth field
    const fd4 = allFields.filter(
      (field: any) => field.parameter.toUpperCase() === "PAP4"
    );

    if (fd4.length > 0) {
      const name = "extraInfo4";
      const label = fd4[0].fieldName;
      const type = fd4[0].type;
      const options =
        type === "SELECT" || type === "LIST"
          ? fd4.map((option: any) => ({
              label: type !== "SELECT" ? option.name : option.code,
              value:
                typeof option.code !== "string"
                  ? JSON.stringify(option.code)
                  : option.code,
            }))
          : undefined;

      if (type === "SELECT" && options)
        options.unshift({ label: translate("empty", "(tuščia)"), value: "" });

      const params = { name, label, options, ref: extra4ref };

      setField4({
        show: true,
        type: type,
        component: componentsList(type, params),
      });
    }

    //Fifth field
    const fd5 = allFields.filter(
      (field: any) => field.parameter.toUpperCase() === "PAP5"
    );

    if (fd5.length > 0) {
      const name = "extraInfo5";
      const label = fd5[0].fieldName;
      const type = fd5[0].type;
      const options =
        type === "SELECT" || type === "LIST"
          ? fd5.map((option: any) => ({
              label: type !== "SELECT" ? option.name : option.code,
              value:
                typeof option.code !== "string"
                  ? JSON.stringify(option.code)
                  : option.code,
            }))
          : undefined;

      if (type === "SELECT" && options)
        options.unshift({ label: translate("empty", "(tuščia)"), value: "" });

      const params = { name, label, options, ref: extra5ref };

      setField5({
        show: true,
        type: type,
        component: componentsList(type, params),
      });
    }
  }, [fieldsList, componentsList, translate]);

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement> & {
      target: HTMLInputElement;
    }
  ) => {
    if (event.key === "Enter") {
      const fieldName =
        event.target && event.target.name ? event.target.name : "";

      switch (fieldName) {
        case "note":
          if (extra1ref.current) {
            extra1ref.current.focus();
          } else if (extra2ref.current) {
            extra2ref.current.focus();
          } else if (extra3ref.current) {
            extra3ref.current.focus();
          } else if (extra4ref.current) {
            extra4ref.current.focus();
          } else if (extra5ref.current) {
            extra5ref.current.focus();
          } else if (buttonRef && buttonRef.current) buttonRef.current.focus();
          break;

        case "extraInfo1":
          if (extra2ref.current) {
            extra2ref.current.focus();
          } else if (extra3ref.current) {
            extra3ref.current.focus();
          } else if (extra4ref.current) {
            extra4ref.current.focus();
          } else if (extra5ref.current) {
            extra5ref.current.focus();
          } else if (buttonRef && buttonRef.current) buttonRef.current.focus();
          break;

        case "extraInfo2":
          if (extra3ref.current) {
            extra3ref.current.focus();
          } else if (extra4ref.current) {
            extra4ref.current.focus();
          } else if (extra5ref.current) {
            extra5ref.current.focus();
          } else if (buttonRef && buttonRef.current) buttonRef.current.focus();
          break;

        case "extraInfo3":
          if (extra4ref.current) {
            extra4ref.current.focus();
          } else if (extra5ref.current) {
            extra5ref.current.focus();
          } else if (buttonRef && buttonRef.current) buttonRef.current.focus();
          break;

        case "extraInfo4":
          if (extra5ref.current) {
            extra5ref.current.focus();
          } else if (buttonRef && buttonRef.current) buttonRef.current.focus();
          break;

        case "extraInfo5":
          if (buttonRef && buttonRef.current) buttonRef.current.focus();
          break;
      }
    }
  };

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        {noteList && noteList.length > 0 ? (
          <OutlinedSelectField
            name="note"
            fullWidth
            label={translate("note", "Pastaba")}
            otherColor="grey"
            options={noteList}
            ref={parentNoteRef ? parentNoteRef : noteref}
          />
        ) : (
          <OutlinedTextField
            fullWidth
            name="note"
            label={translate("note", "Pastaba")}
            otherColor="grey"
            multiline
            ref={parentNoteRef ? parentNoteRef : noteref}
            onFocus={handleFocus}
            maxLength={300}
            onKeyPress={handleKeyPress}
          />
        )}
      </Grid>
      {field1.show && <Grid item>{field1.component}</Grid>}
      {field2.show && <Grid item>{field2.component}</Grid>}
      {field3.show && <Grid item>{field3.component}</Grid>}
      {field4.show && <Grid item>{field4.component}</Grid>}
      {field5.show && <Grid item>{field5.component}</Grid>}
    </Grid>
  );
}

export default ExtraFields;
