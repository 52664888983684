import React from "react";
import {
  Menu as MuiMeniu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";

interface IMenu {
  data: { name: string; onClick?: () => void; icon?: React.ReactNode }[];
  open: boolean;
  onClose: () => void;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
}

function Menu(props: IMenu) {
  const { data, open, onClose, anchorEl } = props;

  return (
    <MuiMeniu open={open} onClose={onClose} anchorEl={anchorEl}>
      {data.map((item) => (
        <MenuItem onClick={item.onClick} key={item.name}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <Typography variant="inherit" noWrap>
            {item.name}
          </Typography>
        </MenuItem>
      ))}
    </MuiMeniu>
  );
}

export default Menu;
