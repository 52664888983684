import React, { useState } from "react";
import { Dialog, Alert } from "./index";
import {
  uiLanguage,
  userClientDb,
  refreshData,
  useExtraField1,
  useExtraField2,
  useExtraField3,
  useExtraField4,
  useExtraField5,
  userDepartmentId,
} from "../modules/auth/selectors/userSelector";
import { setUserRefreshData } from "../modules/auth/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getErrorMessage, getWarningMessage } from "./form/formUtils";
import { Grid } from "@mui/material";
import { useRowAdjustmentsStyles } from "./css";
import { canUserAdjustRowQuantity } from "../modules/api/parameters";
import {
  updateDocDetails,
  IUpdateDocDetails,
} from "../modules/api/docListsandDetails";
import { ItemField } from "./table";
import { useLoader, useAuthorizationError } from "../hooks";
import { isNumber } from "../utils/validate";
import { ExtraFields, OutlinedTextField } from "./form";
import { Form } from "react-final-form";
import { extraFieldsArray } from "../modules/auth/selectors/userSelector";

interface IRowAdjustments {
  open: boolean;
  onClose: () => void;
  rowData: { [key: string]: any };
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

function RowAdjustments(props: IRowAdjustments) {
  const { open, onClose, rowData } = props;
  const extraFieldsList = useSelector(extraFieldsArray);
  const rowQuantity =
    typeof rowData.quantityApp === "string"
      ? parseFloat(rowData.quantityApp)
      : rowData.quantityApp;
  const [showAlert, setShowAlert] = useState(false);
  const [logoutUser] = useAuthorizationError();
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    error: false,
    status: 0,
  });
  const [, setLoader] = useLoader();
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const { classes } = useRowAdjustmentsStyles();
  const dispatch = useDispatch();
  const refresh = useSelector(refreshData);
  const quantityRef = React.useRef<HTMLInputElement>();
  const noteRef = React.useRef<HTMLInputElement>();
  const buttonRef = React.useRef<HTMLButtonElement>();
  const departmentId = useSelector(userDepartmentId);
  const extra1 = useSelector(useExtraField1);
  const extra2 = useSelector(useExtraField2);
  const extra3 = useSelector(useExtraField3);
  const extra4 = useSelector(useExtraField4);
  const extra5 = useSelector(useExtraField5);
  const [allowQtyAdjustments, setAllowQtyAdjustments] = useState<
    boolean | undefined
  >();
  const [scannedData, setScannedData] = useState({
    clientDbId: userDb ? userDb.id : 0,
    locale: locale,
    module: rowData.module || "",
    operationType: rowData.operationType,
    operationCode: rowData.operationCode,
    barcode: rowData.barcode,
    lot: rowData.lot,
    objectCode: rowData.objectCode,
    rowNumber: rowData.rowNumber,
    ignoreWarnings: 0,
    quantityApp: rowQuantity,
    note: rowData?.note || "",
    extraInfo1: rowData?.extraInfo1 || "",
    extraInfo2: rowData?.extraInfo2 || "",
    extraInfo3: rowData?.extraInfo3 || "",
    extraInfo4: rowData?.extraInfo4 || "",
    extraInfo5: rowData?.extraInfo5 || "",
    departmentId: departmentId,
    id: rowData.id,
  });

  const initialValues = {
    note: rowData?.note || "",
    quantityApp: rowQuantity,
    extraInfo1:
      typeof rowData.extraInfo1 === "string" &&
      rowData.extraInfo1.toLowerCase() === "false"
        ? false
        : rowData.extraInfo1,
    extraInfo2:
      typeof rowData.extraInfo2 === "string" &&
      rowData.extraInfo2.toLowerCase() === "false"
        ? false
        : rowData.extraInfo2,
    extraInfo3:
      typeof rowData.extraInfo3 === "string" &&
      rowData.extraInfo3.toLowerCase() === "false"
        ? false
        : rowData.extraInfo3,
    extraInfo4:
      typeof rowData.extraInfo4 === "string" &&
      rowData.extraInfo4.toLowerCase() === "false"
        ? false
        : rowData.extraInfo4,
    extraInfo5:
      typeof rowData.extraInfo5 === "string" &&
      rowData.extraInfo5.toLowerCase() === "false"
        ? false
        : rowData.extraInfo5,
  };

  React.useEffect(() => {
    if (allowQtyAdjustments && quantityRef.current) {
      quantityRef.current.focus();
    } else if (noteRef.current) noteRef.current.focus();
  }, [allowQtyAdjustments]);

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => {
    if (event.key === "Enter") {
      const fieldName =
        event.target && event.target.name ? event.target.name : "";

      if (fieldName === "quantityApp" && noteRef.current) {
        noteRef.current.focus();
      }
    }
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.select();
  };

  React.useEffect(
    () => {
      setLoader(true);
      const params = {
        clientDbId: userDb ? userDb.id : 0,
        locale: locale,
        module: rowData.module || "",
      };

      canUserAdjustRowQuantity(params)
        .then(({ data: dataQty }) => {
          setLoader(false);

          if (dataQty && dataQty[0]) {
            if (dataQty[0].value1?.toUpperCase().includes("T")) {
              setAllowQtyAdjustments(true);
            } else setAllowQtyAdjustments(false);
          }
        })
        .catch((reason) => {
          setLoader(false);
          const message = getErrorMessage(reason);
          setErrorMessage({
            error: true,
            message: message.message,
            status: message.status,
          });

          setShowAlert(true);
        });
    }, // eslint-disable-next-line
    []
  );

  const handleCancelAlert = () => {
    logoutUser(errorMessage.status);

    if (errorMessage.status !== 401) {
      setShowAlert(false);
    }
  };

  const updateData = (params: IUpdateDocDetails) => {
    setLoader(true);

    updateDocDetails(params)
      .then(({ data, warnings }) => {
        setLoader(false);
        //Case when data has a warning message
        if (warnings && warnings.length > 0) {
          const warningMessage = getWarningMessage(warnings);

          setErrorMessage({
            message: warningMessage,
            error: false,
            status: 0,
          });

          setShowAlert(true);
          return;
        }
        if (data && data.length > 0) {
          dispatch(setUserRefreshData(!refresh));
        }
      })
      .catch((reason) => {
        setLoader(false);
        const message = getErrorMessage(reason);
        setErrorMessage({
          error: true,
          message: message.message,
          status: message.status,
        });
        setShowAlert(true);
      });
  };

  const handleSubmit = (values: any) => {
    const enteredQty =
      values.quantityApp && typeof values.quantityApp === "string"
        ? parseFloat(values.quantityApp.replace(",", "."))
        : values.quantityApp;

    let opParams = {
      ...scannedData,
      quantityApp: enteredQty - rowQuantity,
      note: values.note || "",
      extraInfo1:
        typeof values.extraInfo1 === "undefined" ? "" : values.extraInfo1,
      extraInfo2:
        typeof values.extraInfo2 === "undefined" ? "" : values.extraInfo2,
      extraInfo3:
        typeof values.extraInfo3 === "undefined" ? "" : values.extraInfo3,
      extraInfo4:
        typeof values.extraInfo4 === "undefined" ? "" : values.extraInfo4,
      extraInfo5:
        typeof values.extraInfo5 === "undefined" ? "" : values.extraInfo5,
    };

    if (values.extraInfo1 && extra1.type.toUpperCase() === "$N")
      opParams = {
        ...opParams,
        extraInfo1:
          typeof values.extraInfo1 === "string"
            ? values.extraInfo1.replace(",", ".")
            : values.extraInfo1,
      };

    if (values.extraInfo2 && extra2.type.toUpperCase() === "$N")
      opParams = {
        ...opParams,
        extraInfo2:
          typeof values.extraInfo2 === "string"
            ? values.extraInfo2.replace(",", ".")
            : values.extraInfo2,
      };

    if (values.extraInfo3 && extra3.type.toUpperCase() === "$N")
      opParams = {
        ...opParams,
        extraInfo3:
          typeof values.extraInfo3 === "string"
            ? values.extraInfo3.replace(",", ".")
            : values.extraInfo3,
      };

    if (values.extraInfo4 && extra4.type.toUpperCase() === "$N")
      opParams = {
        ...opParams,
        extraInfo4:
          typeof values.extraInfo4 === "string"
            ? values.extraInfo4.replace(",", ".")
            : values.extraInfo4,
      };

    if (values.extraInfo5 && extra5.type.toUpperCase() === "$N")
      opParams = {
        ...opParams,
        extraInfo5:
          typeof values.extraInfo5 === "string"
            ? values.extraInfo5.replace(",", ".")
            : values.extraInfo5,
      };

    setScannedData(opParams);
    updateData(opParams);
  };

  const handleConfirmAlert = () => {
    const opParams = {
      ...scannedData,
      ignoreWarnings: 1,
    };
    setShowAlert(false);
    updateData(opParams);
  };

  return (
    <div>
      {showAlert && (
        <Alert
          message={errorMessage.message}
          isOpen={showAlert}
          onClose={handleCancelAlert}
          isConfirmationButton={!errorMessage.error}
          onConfirm={handleConfirmAlert}
          iconColor={!errorMessage.error ? "warning" : "error"}
        />
      )}

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        subscription={subscription}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} method="post">
            <Dialog
              open={open}
              onClose={onClose}
              isConfirmationButton
              onConfirm={handleSubmit}
              confirmButtonRef={buttonRef}
              title={translate("rowAdjustment", "Eilutės koregavimas")}
            >
              <Grid container spacing={1} direction="column">
                <Grid item xs={12} className={classes.container}>
                  <ItemField data={rowData} disableMenu={true} />
                </Grid>

                {allowQtyAdjustments && (
                  <Grid item xs={12}>
                    <OutlinedTextField
                      fullWidth
                      label={translate("quantityapp", "Atrinktas kiekis")}
                      name="quantityApp"
                      otherColor="grey"
                      inputMode="decimal"
                      ref={quantityRef}
                      onFocus={handleFocus}
                      onKeyPress={handleKeyPress}
                      validate={isNumber}
                      autoComplete="off"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <ExtraFields
                    fieldsList={extraFieldsList}
                    buttonRef={buttonRef}
                    noteRef={noteRef}
                  />
                </Grid>
              </Grid>
            </Dialog>
          </form>
        )}
      />
    </div>
  );
}

export default RowAdjustments;
