import { makeStyles } from "tss-react/mui";

const useInfoButtonStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  text: {
    padding: theme.spacing(2),
  },
}));

export default useInfoButtonStyles;
