import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export interface ICompleteTheOperation {
  clientDbId: number;
  locale: string;
  module: string;
  operationType: string;
  operationCode: string;
  finalizeOption?: number;
  note?: string;
  departmentId?: string;
}

export const checkDetails = (params: ICompleteTheOperation) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_FINALIZE_CHECK",
    data: {
      module: params.module,
      operationCode: params.operationCode,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const completeTheOperation = (params: ICompleteTheOperation) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_FINALIZE",
    data: {
      module: params.module,
      operationType: params.operationType,
      operationCode: params.operationCode,
      finalizeOption: params.finalizeOption ? params.finalizeOption : 0,
      note: params.note || "",
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const runClientsProcedure = (params: ICompleteTheOperation) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_FINALIZE_CLIENT",
    data: {
      module: params.module,
      operationType: params.operationType,
      operationCode: params.operationCode,
      finalizeOption: params.finalizeOption ? params.finalizeOption : 0,
      note: params.note || "",
      departmentId: params.departmentId ? params.departmentId : undefined,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};
