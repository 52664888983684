import React, { useState } from "react";
import { ScannerForm } from "../../../components/form";
import { userClientDb } from "../../auth/selectors/userSelector";
import { useSelector } from "react-redux";
import * as modules from "../../../utils/modules";
import {
  getProductInfoByBarcode,
  getProductInfoCustom,
} from "../../api/productInfo";
import { InfiniteScrollList } from "../../../components/table";
import { Card, CardMedia, Backdrop } from "@mui/material";
import useProductInfoFormStyles from "../css/useProductInfoFormStyles";
import { useDoubleTap } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../../hooks";
import { moduleType } from "../../../utils/modules";

function ProductInfoForm() {
  const [data, setData] = useState<{ property: string; value: any }[]>();
  const userDb = useSelector(userClientDb);
  const userDbId = userDb ? userDb.id : 0;
  const [zoomImage, setZoomImage] = useState(false);
  const { classes } = useProductInfoFormStyles();
  const [imageLink, setImageLink] = useState("");
  const divRef = React.useRef<HTMLDivElement>(null);
  const params: {
    module: moduleType;
    cellType: "info";
    ref: React.RefObject<HTMLDivElement>;
  } = {
    module: modules.MODULE_PRODUCT,
    cellType: "info",
    ref: divRef,
  };

  const [isCustomProc] = useLocalStorage("productCustomProc");
  const [translate] = useTranslation();

  const zoom = () => setZoomImage(!zoomImage);
  const [refCallback] = useDoubleTap(zoom);
  const [refCallback2] = useDoubleTap(zoom);

  const updateData = React.useCallback((updatedData) => {
    if (updatedData && updatedData.length > 0 && updatedData[0]) {
      //In this version component doesn't work with schema. It'll work in next version
      if (updatedData[0].schema) {
        delete updatedData[0].schema;
      }

      let infoArray = Object.keys(updatedData[0]).map((key) => ({
        property: key,
        value: updatedData[0][key],
      }));

      const isImage = infoArray.filter(
        (object) =>
          object.property &&
          object.property.toLocaleLowerCase() === "img_link" &&
          typeof object.value === "string" &&
          object.value.trim() !== ""
      );

      if (isImage.length > 0) {
        infoArray = infoArray.filter(
          (object) => object.property.toLocaleLowerCase() !== "img_link"
        );
        setImageLink(isImage[0].value);
      } else setImageLink("");

      setData(infoArray);
    }
  }, []);

  React.useEffect(() => {
    if (divRef.current) divRef.current.scrollTop = 0;
  }, [data]);

  console.log(data);

  return (
    <React.Fragment>
      <ScannerForm
        module={modules.MODULE_PRODUCT}
        userDb={userDbId}
        updateData={updateData}
        onSubmit={isCustomProc ? getProductInfoCustom : getProductInfoByBarcode}
      />

      {imageLink && (
        <div>
          <Card>
            <CardMedia
              component="img"
              image={imageLink}
              className={classes.image}
              ref={refCallback}
              alt={translate(
                "imageUploadFailed",
                "Nepavyko įkelti paveikslėlio"
              )}
            />
          </Card>

          <Backdrop open={zoomImage} className={classes.backdrop}>
            <img
              src={imageLink}
              height="100%"
              width="100%"
              className={classes.zoomedImage}
              ref={refCallback2}
              alt={translate(
                "imageUploadFailed",
                "Nepavyko įkelti paveikslėlio"
              )}
            />
          </Backdrop>
        </div>
      )}

      <InfiniteScrollList initialData={data} loadDataparams={params} />
    </React.Fragment>
  );
}

export default ProductInfoForm;
