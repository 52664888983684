import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export interface ICreateScannerOperation {
  clientDbId: number;
  locale: string;
  documentNr: string;
  name: string;
}

export const createScannerOperation = (params: ICreateScannerOperation) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_CREATE_KAUP_DOC",
    data: {
      documentNr: params.documentNr,
      name: params.name,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IGetScannerOpName {
  clientDbId: number;
  locale: string;
}

export const getScannerOpName = (params: IGetScannerOpName) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_KAUP_DOC_NAME",
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};
