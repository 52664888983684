import { makeStyles } from "tss-react/mui";

const useInfiniteScrollListStyles = makeStyles()(() => ({
  mainContainer: {
    flexGrow: 2,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  scrollableDiv: {
    height: 50,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
  },
  noRowsContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  noRows: {
    textAlign: "center",
  },
}));

export default useInfiniteScrollListStyles;
