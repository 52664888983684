import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export const getDocHeadersList = (params: { [name: string]: any }) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_LIST_DOC",
    data: {
      module: params.module,
      departmentId: params.departmentId ? params.departmentId : undefined,
      pageNumber: params.pageNumber,
      top: params.top || 100,
      filApp: params.filApp,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const getDocHeaderById = (params: { [name: string]: any }) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_LIST_DOC",
    data: {
      module: params.module,
      fil: `operationCode='${params.operationCode}'`,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const getDocDetails = (
  params: { [name: string]: any },
  config?: Record<string, unknown>
) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_LIST_DET",
    data: {
      module: params.module,
      operationCode: params.operationCode,
      departmentId: params.departmentId ? params.departmentId : undefined,
      pageNumber: params.pageNumber,
      top: params.top || 100,
      filApp: params.filApp,
    },
  };

  const finalConfig = { ...config };
  if (!finalConfig.headers) {
    finalConfig.headers = {};
  }

  finalConfig.headers = {
    "accept-language": params.locale,
  };

  return client
    .post("/client/application", query, finalConfig)
    .then(formatAppListResponse);
};

export interface IUpdateDocDetails {
  clientDbId: number;
  locale: string;
  module: string;
  operationType: string;
  operationCode: string;
  barcode: string;
  quantityApp: number;
  lot: string;
  objectCode: string;
  ignoreWarnings?: number;
  note?: string;
  rowNumber?: number;
  extraInfo1?: string;
  extraInfo2?: string;
  extraInfo3?: string;
  extraInfo4?: string;
  extraInfo5?: string;
  departmentId?: string;
}

export const updateDocDetails = (params: { [name: string]: any }) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_EDIT_D",
    data: {
      module: params.module,
      operationType: params.operationType,
      operationCode: params.operationCode,
      mscanCode: params.barcode,
      quantityApp: params.quantityApp,
      lot: params.lot,
      objectCode: params.objectCode,
      ignoreWarnings: params.ignoreWarnings ? params.ignoreWarnings : 0,
      note: params.note,
      rowNumber: params.rowNumber,
      extraInfo1: params.extraInfo1,
      extraInfo2: params.extraInfo2,
      extraInfo3: params.extraInfo3,
      extraInfo4: params.extraInfo4,
      extraInfo5: params.extraInfo5,
      scannedWithCamera: params.scannedWithCamera ? 1 : 0,
      departmentId: params.departmentId ? params.departmentId : undefined,
      id: params.id,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IManageIsFullyColected {
  clientDbId: number;
  locale: string;
  module: string;
  operationType: string;
  operationCode: string;
  rowNumber: number;
  isFullyCollected: number;
  lot: string;
  objectCode: string;
  productCode: string;
  unitCode: string;
  barcode: string;
}

export const manageIsFullyCollected = (params: IManageIsFullyColected) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_MANAGE_IS_FULLY_COLLECTED",
    data: {
      module: params.module,
      operationType: params.operationType,
      operationCode: params.operationCode,
      rowNumber: params.rowNumber,
      isFullyCollected: params.isFullyCollected,
      lot: params.lot,
      objectCode: params.objectCode,
      productCode: params.productCode,
      barcode: params.barcode,
      unitCode: params.unitCode,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IDeleteRow {
  clientDbId: number;
  module: string;
  operationType: string;
  operationCode: string;
  rowNumber: number;
  id: string | number;
  locale: string;
}

export const deleteRecord = (params: IDeleteRow) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_DELETE_REC",
    data: {
      module: params.module,
      operationType: params.operationType,
      operationCode: params.operationCode,
      rowNumber: params.rowNumber,
      id: params.id,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};
