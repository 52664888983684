import React from "react";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
import { useCheckBoxStyles } from "./css";

export interface ICheckboxExampleState {
  disabled?: boolean;
  checked?: boolean;
  value: any;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rightElement?: React.ReactNode;
  labelColor?: "black" | "white";
}

const Checkbox = React.forwardRef<any, ICheckboxExampleState>((props, ref) => {
  const {
    label,
    onChange,
    value,
    rightElement,
    labelColor = "white",
    ...rest
  } = props;
  const { classes, cx } = useCheckBoxStyles();

  return (
    <div className={classes.grid}>
      <FormControlLabel
        label={label}
        ref={ref}
        control={
          <MuiCheckbox
            color="primary"
            className={cx({ [classes.checkbox]: labelColor === "white" })}
            onChange={onChange}
            value={value}
          />
        }
        className={cx({ [classes.root]: labelColor === "white" })}
        {...rest}
      />
      {rightElement}
    </div>
  );
});

export default Checkbox;
