import React, { useState } from "react";
import Spinner from "./Spinner";

interface ILoaderProvider {
  children?: React.ReactNode;
}

export const LoaderContext = React.createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]);

function LoaderProvider(props: ILoaderProvider) {
  const { children } = props;
  const [isLoading, setLoader] = useState(false);

  const contextValue: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = React.useMemo(() => {
    return [isLoading, setLoader];
  }, [isLoading, setLoader]);

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
      <Spinner open={isLoading} />
    </LoaderContext.Provider>
  );
}

export default LoaderProvider;
