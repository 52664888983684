import { makeStyles } from "tss-react/mui";

const useNavigationBarStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: "600px",
    right: "auto",
  },
  icon: {
    color: theme.palette.common.white,
  },
  container: {
    backgroundColor: theme.palette.secondary.main,
  },
  gridContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
    WebkitFlexWrap: "nowrap",
  },
  leftItem: {
    width: "45%",
  },
  rightItem: {
    width: "55%",
    textAlign: "right",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  textAndIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useNavigationBarStyles;
