import React from "react";
import { InfiniteScrollList } from "../../../components/table";
import { getDocHeadersList } from "../../api/docListsandDetails";
import * as routes from "../../../app/routes";
import * as modules from "../../../utils/modules";
import { moduleType } from "../../../utils/modules";

function PurchasesList() {
  const params = React.useMemo<{
    module: moduleType;
    cellType: "header" | "info" | "item";
    link: string;
    linkId?: "operationCode";
  }>(
    () => ({
      module: modules.MODULE_PURCHASES,
      cellType: "header",
      link: routes.ROUTE_PURCHASES_DETAILS.path,
      linkId: "operationCode",
    }),
    []
  );

  return (
    <InfiniteScrollList
      loadData={getDocHeadersList}
      loadDataparams={params}
      externalFilter
    />
  );
}

export default PurchasesList;
