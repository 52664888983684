import { combineReducers } from "@reduxjs/toolkit";
import auth from "../../modules/auth/slices/authSlice";
import user from "../../modules/auth/slices/userSlice";

const rootReducer = combineReducers({
  // global reducers
  user,
  // module reducers
  auth,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
