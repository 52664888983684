import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { IconButton, Link, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  OutlinedCheckbox,
  OutlinedTextField,
  SubmitButton,
} from "../../../components/form";
import { useLoginStyles } from "../css";
import { authError } from "../selectors/authSelectors";
import { doLogIn } from "../slices/authSlice";
import { clearUserDetails } from "../slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";

const initialValues = { username: "", password: "" };
const subscription = { submitError: true, submitting: true };
const linkChangePassword = `${window.__ENV.REACT_APP_LINK_TO_CHNG_PASSWORD}?app=mscan`;

function LoginForm() {
  const { classes } = useLoginStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const loginError = useSelector(authError);
  const [translate] = useTranslation();
  const tooltipLabel = showPassword
    ? translate("hidePassword", "Slėpti slaptažodį")
    : translate("showPassword", "Rodyti slaptažodį");
  const location = useLocation();
  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    (values) => {
      dispatch(
        doLogIn(
          values.usernameMSCAN,
          values.passwordMSCAN,
          values.rememberMe || false
        )
      );
    },
    [dispatch]
  );

  const handleShowClick = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  useEffect(() => {
    dispatch(clearUserDetails());
    localStorage.removeItem("lastUser");
  }, []);

  useEffect(() => {
    const version = localStorage.getItem("mscanVersija");

    if (version && version === "v3") {
      const currentPath = location.pathname;
      const newPath = `/v3${currentPath}`;
      navigate(newPath, { replace: true });
      window.location.reload();
    }
  }, []);

  return (
    <div>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={subscription}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit} method="post">
            {submitError && (
              <Typography color="error">{submitError}</Typography>
            )}

            <div className={classes.root}>
              <div className={classes.spacing}>
                <OutlinedTextField
                  type="text"
                  name="usernameMSCAN"
                  label={translate("username", "Vartotojas")}
                  autoComplete="username"
                />
              </div>
              <div className={classes.spacing05}>
                <OutlinedTextField
                  type={showPassword ? "text" : "password"}
                  name="passwordMSCAN"
                  autoComplete="current-password"
                  label={translate("password", "Slaptažodis")}
                  endAdornment={
                    <Tooltip title={tooltipLabel}>
                      <IconButton onClick={handleShowClick} size="large">
                        {showPassword ? (
                          <VisibilityOutlined
                            className={classes.icon}
                            fontSize="small"
                          />
                        ) : (
                          <VisibilityOffOutlined
                            className={classes.icon}
                            fontSize="small"
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  }
                />
              </div>
              <div className={classes.spacing}>
                <OutlinedCheckbox
                  name="rememberMe"
                  label={translate("rememberMe", "Prisiminti mane")}
                />
              </div>
              {loginError ? (
                <div className={classes.spacing}>
                  <Typography color="error" className={classes.errorText}>
                    {translate(
                      "wrongPassword",
                      "Neteisingai nurodytas vartotojo vardas ar slaptažodis"
                    )}
                  </Typography>
                </div>
              ) : (
                ""
              )}

              <div>
                <SubmitButton>
                  {submitting
                    ? translate("loggingIn", "Jungiamasi...")
                    : translate("login", "Prisijungti")}
                </SubmitButton>
              </div>
            </div>
          </form>
        )}
      </Form>
      <div className={classes.password}>
        <Link color="inherit" underline="always" href={linkChangePassword}>
          {translate("forgotPassword", "Pamiršote slaptažodį?")}
        </Link>
      </div>
    </div>
  );
}

export default LoginForm;
