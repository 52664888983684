import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export interface ICreateInventoryOperation {
  clientDbId: number;
  locale: string;
  inventoryListCode: string;
  description: string;
}

export const createInventoryOperation = (params: ICreateInventoryOperation) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_CREATE_IV_DOC",
    data: {
      inventoryListCode: params.inventoryListCode,
      description: params.description,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IGetInventoryParams {
  clientDbId: number;
  locale: string;
  departmentId?: string;
}

export const getInventoryParams = (params: IGetInventoryParams) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_INVENTORY_PARAMS",
    data: {
      departmentId: params.departmentId ? params.departmentId : undefined,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};
