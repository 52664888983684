import { makeStyles } from "tss-react/mui";

const useFabStyles = makeStyles()((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.grey.A100,
  },
  icon: {
    color: theme.palette.grey.A700,
  },
}));

export default useFabStyles;
