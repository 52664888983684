import { configureStore, Action, Store } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import newRootReducer from "./rootReducer";

// eslint-disable-next-line
import rootReducer from "./rootReducer";

import type { RootState } from "./rootReducer";
import { Dispatch } from "react";

const store: Store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    // const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

// export type AppDispatch = typeof store.dispatch;
export type AppDispatch = Dispatch<any>;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
