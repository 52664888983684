import React, { useState } from "react";
import { BaseLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import * as routes from "../../app/routes";
import { ScannerList, ScannerFormCreate } from "./components";

function Scanner() {
  const [translate] = useTranslation();
  const [createOp, setCreateOp] = useState(false);

  const handleClick = () => {
    setCreateOp(true);
  };

  const handleDialogClosing = () => {
    setCreateOp(false);
  };

  return (
    <BaseLayout
      moduleName={translate("caches", "Kaupikliai")}
      modulePath={routes.ROUTE_SCANNER.path}
      isDocumentList
      button={{
        btnIcon: <Add />,
        btnText: translate("new", "Nauja"),
        btnOnClick: handleClick,
      }}
    >
      {createOp && (
        <ScannerFormCreate open={createOp} onClose={handleDialogClosing} />
      )}

      <ScannerList />
    </BaseLayout>
  );
}

export default Scanner;
