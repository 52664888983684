import { makeStyles } from "tss-react/mui";

const useBaseLayoutStyles = makeStyles()((theme) => ({
  layout: {
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: theme.palette.grey.A100,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
}));

export default useBaseLayoutStyles;
