import { makeStyles } from "tss-react/mui";

const useRowAdjustmentsStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.grey.A100,
    width: "100%",
  },
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    display: "inline-block",
  },
}));

export default useRowAdjustmentsStyles;
