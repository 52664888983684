import React, { useState } from "react";
import PurchasesList from "./components/PurchasesList";
import PurchasesCreate from "./components/PurchaseCreate";
import { BaseLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import * as routes from "../../app/routes";
import { Add } from "@mui/icons-material";

function Purchases() {
  const [translate] = useTranslation();
  const [createOp, setCreateOp] = useState(false);

  const handleClick = () => {
    setCreateOp(true);
  };

  const handleDialogClosing = () => {
    setCreateOp(false);
  };

  return (
    <BaseLayout
      moduleName={translate("purchases", "Pirkimai")}
      modulePath={routes.ROUTE_PURCHASES.path}
      isDocumentList
      button={{
        btnIcon: <Add />,
        btnText: translate("new", "Nauja"),
        btnOnClick: handleClick,
      }}
    >
      {createOp && (
        <PurchasesCreate open={createOp} onClose={handleDialogClosing} />
      )}
      <PurchasesList />
    </BaseLayout>
  );
}

export default Purchases;
