import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Dialog } from "../index";
import { Form } from "react-final-form";
import { Grid } from "@mui/material";
import { ExtraFields } from "./index";
import {
  useExtraField1,
  useExtraField2,
  useExtraField3,
  useExtraField4,
  useExtraField5,
} from "../../modules/auth/selectors/userSelector";
import { useSelector } from "react-redux";

interface IExtraFieldsForms {
  open: boolean;
  onClose: (event?: Record<string, unknown>) => void;
  fieldsList?: { [name: string]: any }[];
  onConfirm: (values: Record<string, unknown>) => void;
  initialValues?: Record<string, unknown>;
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

function ExtraFieldsForm(props: IExtraFieldsForms) {
  const {
    open,
    onClose,
    fieldsList,
    onConfirm,
    initialValues: userInitialValues,
  } = props;
  const [translate] = useTranslation();
  const [showAlert, setShowAlert] = useState({ show: false, message: "" });
  const buttonRef = React.useRef<HTMLButtonElement>();
  const extra1 = useSelector(useExtraField1);
  const extra2 = useSelector(useExtraField2);
  const extra3 = useSelector(useExtraField3);
  const extra4 = useSelector(useExtraField4);
  const extra5 = useSelector(useExtraField5);

  const initialValues = React.useMemo(
    () => ({
      extraInfo1: undefined,
      extraInfo2: undefined,
      extraInfo3: undefined,
      extraInfo4: undefined,
      extraInfo5: undefined,
      note: undefined,
      ...userInitialValues,
    }),
    [userInitialValues]
  );

  const handleCancelAlert = () => {
    setShowAlert({ show: false, message: "" });
    onClose();
  };

  const handleSubmit = (values: any) => {
    let allValues = { ...values };

    if (values.extraInfo1 && extra1.type.toUpperCase() === "$N")
      allValues = {
        ...allValues,
        extraInfo1:
          typeof values.extraInfo1 === "string"
            ? values.extraInfo1.replace(",", ".")
            : values.extraInfo1,
      };

    if (values.extraInfo2 && extra2.type.toUpperCase() === "$N")
      allValues = {
        ...allValues,
        extraInfo2:
          typeof values.extraInfo2 === "string"
            ? values.extraInfo2.replace(",", ".")
            : values.extraInfo2,
      };

    if (values.extraInfo3 && extra3.type.toUpperCase() === "$N")
      allValues = {
        ...allValues,
        extraInfo3:
          typeof values.extraInfo3 === "string"
            ? values.extraInfo3.replace(",", ".")
            : values.extraInfo3,
      };

    if (values.extraInfo4 && extra4.type.toUpperCase() === "$N")
      allValues = {
        ...allValues,
        extraInfo4:
          typeof values.extraInfo4 === "string"
            ? values.extraInfo4.replace(",", ".")
            : values.extraInfo4,
      };

    if (values.extraInfo5 && extra5.type.toUpperCase() === "$N")
      allValues = {
        ...allValues,
        extraInfo5:
          typeof values.extraInfo5 === "string"
            ? values.extraInfo5.replace(",", ".")
            : values.extraInfo5,
      };

    onConfirm(allValues);
  };

  return (
    <div>
      {showAlert.show && (
        <Alert
          message={showAlert.message}
          isOpen={showAlert.show}
          onClose={handleCancelAlert}
          iconColor="error"
        />
      )}

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        subscription={subscription}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} method="post">
            <Dialog
              open={open}
              onClose={onClose}
              isConfirmationButton
              onConfirm={handleSubmit}
              confirmButtonRef={buttonRef}
              title={translate("extraInfo", "Papildoma info.")}
            >
              <Grid container direction="column" spacing={1}>
                <ExtraFields fieldsList={fieldsList} buttonRef={buttonRef} />
              </Grid>
            </Dialog>
          </form>
        )}
      />
    </div>
  );
}

export default ExtraFieldsForm;
