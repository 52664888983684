import React, { ReactNode } from "react";
import { ErrorBoundary, NavigationBar } from "../components";
import { useBaseLayoutStyles } from "./css";

interface IBaseLayout {
  children: ReactNode;
  moduleName?: string;
  modulePath?: string;
  isDocumentList?: boolean;
  button?: {
    btnIcon?: React.ReactNode;
    btnText?: string;
    btnOnClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    btnLink?: string;
  };
}

export default function BaseLayout(props: IBaseLayout) {
  const {
    children,
    moduleName,
    button,
    modulePath,
    isDocumentList = false,
  } = props;
  const { classes } = useBaseLayoutStyles();

  return (
    <div className={classes.layout}>
      <NavigationBar
        moduleName={moduleName}
        modulePath={modulePath}
        isDocumentList={isDocumentList}
        {...button}
      />
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}
