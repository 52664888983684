import React from "react";
import { DocumentDetails } from "../../../components";
import * as routes from "../../../app/routes";
import { useParams } from "react-router-dom";
import * as modules from "../../../utils/modules";

function InternalMovementsDetails() {
  const parameters = useParams<{ id: string }>();
  const id = parameters.id || "";

  return (
    <DocumentDetails
      module={modules.MODULE_INTERNAL}
      operationCode={id}
      headerRoute={routes.ROUTE_INTERNAL.path}
    />
  );
}

export default InternalMovementsDetails;
