import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export interface IParameters {
  clientDbId: number;
  locale: string;
  module: string;
  operationType: string;
}

export const canUserModifyQuantities = (params: IParameters) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_USER_FINALIZE_OPTIONS",
    data: {
      module: params.module,
      operationType: params.operationType,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IParams {
  clientDbId: number;
  locale: string;
  module: string;
}

export const getRowAdjustmentParams = (params: IParams) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_PARAM_VALUES",
    data: {
      module: params.module,
      parameter: "MSCAN_DPAS",
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const getHeaderAdjustmentParams = (params: IParams) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_PARAM_VALUES",
    data: {
      module: params.module,
      parameter: "MSCAN_HPAS",
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const canUserAdjustRowQuantity = (params: IParams) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_PARAM_VALUES",
    data: {
      module: params.module,
      parameter: "MSCAN_KIEKOR",
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export const getModuleParams = (params: IParams) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_MODULE_PARAMS",
    data: {
      module: params.module,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

interface ICheckForCustomProcedures {
  clientDbId: number;
  locale: string;
}
export const checkForCustomProcedures = (params: ICheckForCustomProcedures) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_CHECK_CUSTOM_PROC",
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};
