import React from "react";
import { useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { getIn } from "final-form";

interface IWhenFieldChanges {
  field: string;
  onChange: (value: any) => void;
}

const WhenFieldChanges = (props: IWhenFieldChanges) => {
  const { field, onChange } = props;
  const { values } = useFormState();

  return (
    <OnChange name={field}>
      {() => {
        if (onChange) onChange(getIn(values, field));
      }}
    </OnChange>
  );
};

export default React.memo(WhenFieldChanges);
