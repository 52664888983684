import React, { useState } from "react";
import InternalMovementsList from "./components/InternalMovementsList";
import InternalMovementsCreate from "./components/InternalMovementsCreate";
import { BaseLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import * as routes from "../../app/routes";
import { Add } from "@mui/icons-material";

function InternalMovements() {
  const [translate] = useTranslation();
  const [createOp, setCreateOp] = useState(false);

  const handleClick = () => {
    setCreateOp(true);
  };

  const handleDialogClosing = () => {
    setCreateOp(false);
  };

  return (
    <BaseLayout
      moduleName={translate("insideAccounting", "Vidiniai")}
      modulePath={routes.ROUTE_INTERNAL.path}
      isDocumentList
      button={{
        btnIcon: <Add />,
        btnText: translate("new", "Nauja"),
        btnOnClick: handleClick,
      }}
    >
      {createOp && (
        <InternalMovementsCreate
          open={createOp}
          onClose={handleDialogClosing}
        />
      )}
      <InternalMovementsList />
    </BaseLayout>
  );
}

export default InternalMovements;
