import React from "react";
import { Field } from "react-final-form";
import { showError } from "./formUtils";
import Select, { ISelect } from "../Select";

interface IOutlinedSelectField extends ISelect {
  validate?: (value: any) => any;
  name: string;
  otherColor?: "white" | "grey";
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => void;
  options: { value?: string | number; label?: string }[] | undefined;
}

const OutlinedSelectField = React.forwardRef<any, IOutlinedSelectField>(
  (props, ref) => {
    const {
      disabled,
      helperText: userHelperText,
      endAdornment,
      label,
      otherColor,
      onKeyPress,
      onFocus,
      options,
      ...rest
    } = props;

    return (
      <Field
        render={({ input, meta, ...restTextField }) => {
          const isError = showError(meta);
          const helperText = isError ? meta.error : userHelperText;

          return (
            <div>
              <Select
                {...restTextField}
                {...input}
                disabled={meta.submitting || disabled}
                options={options}
                error={isError}
                helperText={helperText}
                label={label}
                endAdornment={endAdornment}
                otherColor={otherColor}
                onKeyPress={onKeyPress}
                onFocus={onFocus}
                ref={ref}
              />
            </div>
          );
        }}
        {...rest}
      />
    );
  }
);

export default React.memo(OutlinedSelectField);
