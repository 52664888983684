import React from "react";
import { useTranslation } from "react-i18next";
import { Drawer as MuiDrawer, Divider, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDrawerStyles } from "./css";

interface IDrawer {
  children?: string | JSX.Element | undefined;
  data?: { [name: string]: any };
  isOpen: boolean;
  onClose: (event: {}) => void;
  onConfirm?: (event: {}) => void;
  iconColor?: "error" | "warning";
  isCloseButton?: boolean;
  isConfirmationButton?: boolean;
  message?: string;
}

function Drawer(props: IDrawer) {
  const { isOpen = false, children, onClose, data } = props;
  const dataArray = data ? Object.entries(data) : [];
  const [translate] = useTranslation();
  const { classes } = useDrawerStyles();

  return (
    <MuiDrawer
      open={isOpen}
      anchor="bottom"
      elevation={0}
      variant="temporary"
      onClose={onClose}
      classes={{ root: classes.root, paper: classes.paper }}
    >
      <div className={classes.closeButton}>
        <IconButton onClick={onClose} size="large">
          <Close fontSize="large" className={classes.icon} />
        </IconButton>
        <Divider />
      </div>

      {children}
      {dataArray
        ? dataArray.map((item) => {
            return (
              <div key={item[0]}>
                <div className={classes.container}>
                  <div className={classes.left}>
                    <div className={classes.words}>
                      {translate(
                        item[0].toLowerCase(),
                        item[0].charAt(0).toUpperCase() + item[0].slice(1)
                      )}
                    </div>
                  </div>
                  <div className={classes.right}>
                    <div className={classes.words}>{item[1]}</div>
                  </div>
                </div>
                <Divider />
              </div>
            );
          })
        : ""}
    </MuiDrawer>
  );
}

export default Drawer;
