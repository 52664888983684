import { client } from "./utils";

export const login = (
  username: string,
  password: string,
  rememberMe: boolean,
  device: string,
  application = "MSCAN"
) =>
  client.post("/auth/login", {
    username,
    password,
    rememberMe,
    device,
    application,
  });

export const profile = () => client.get("/auth/profile");

export const logout = () => client.post("/auth/logout");

export const refresh = () => client.post("/auth/refresh");
