import React from "react";
import { useField } from "react-final-form";
import { showError } from "./formUtils";
import { Autocomplete } from "@mui/material";
import TextField from "../TextField";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useOutlinedAutocompleteStyles } from "./css";
import { useMediaQuery, useTheme } from "@mui/material";
import { matchSorter } from "match-sorter";

function filterOptions(
  options: {
    label?: string | undefined;
    value?: string | number | undefined;
  }[],
  {
    inputValue,
  }: {
    inputValue: any;
  }
) {
  return matchSorter(options, inputValue, {
    keys: ["label", "value"],
    keepDiacritics: false,
    threshold: matchSorter.rankings.CONTAINS,
    sorter: (rankedItems) => rankedItems,
  });
}

interface IOutlinedAutocomplete {
  validate?: (value: any) => any;
  name: string;
  otherColor?: "white" | "grey";
  options: { value?: string | number; label?: string }[];
  disabled?: boolean;
  label?: string;
  fullWidth?: boolean;
  helperText?: string;
  required?: boolean;
  endAdornment?: JSX.Element;
  inputValue?: boolean;
  id?: string;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => void;
  loading?: boolean;
  ListboxComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
  ListboxProps?: { [key: string]: any };
  renderType?: "renderExtra" | "renderSimple" | "renderDefault";
  defaultValue?: { value?: string | number; label?: string };
}

const OutlinedAutocomplete = React.forwardRef<any, IOutlinedAutocomplete>(
  (props, ref) => {
    const {
      name,
      validate,
      label,
      options,
      disabled,
      fullWidth = true,
      helperText: userHelperText,
      otherColor = "grey",
      required,
      endAdornment,
      onKeyPress,
      onFocus,
      inputValue,
      id,
      loading,
      ListboxComponent,
      ListboxProps,
      renderType,
      defaultValue,
      ...rest
    } = props;

    const { classes, cx } = useOutlinedAutocompleteStyles();
    const [translate] = useTranslation();
    const { input, meta, ...restTextField } = useField(name, { validate });
    const isError = showError(meta);
    const helperText = isError ? meta.error : userHelperText;
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.between(0, 300));
    const labelLength = xsScreen ? 40 : 60;

    const renderOptionDefault = (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: {
        label?: string;
        value?: string | number;
      }
    ) => (
      <li {...props} className={classes.renderOpDiv} key={option.value}>
        <span style={{ fontWeight: "bold" }}>{option.value || ""}</span>
        {option.label ? (option.value ? ": " : "") + option.label : ""}
      </li>
    );
    const renderOptionSimple = (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: {
        label?: string;
        value?: string | number;
      }
    ) => (
      <li {...props} key={option.value}>
        {option.value}
      </li>
    );

    const renderOptionExtra = (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: {
        label?: string;
        value?: string | number;
      }
    ) => {
      let value = "" + option.value;
      let label = option.label ? option.label.trim() : value;
      const isColon = label ? label.indexOf(":") : -1;
      const lblLen = label.length;

      if (isColon > -1) {
        value = label ? label.substring(0, isColon) : "";
        label = label
          ? label.substring(isColon + 1, labelLength) +
            (lblLen > labelLength ? "..." : "")
          : "";
      } else {
        value = "";
        label =
          label.substring(0, labelLength) + (lblLen > labelLength ? "..." : "");
      }

      return (
        <li {...props} className={classes.renderOpDiv} key={option.value}>
          <span style={{ fontWeight: "bold" }}>{value}</span>
          {(value ? ": " : "") + label}
        </li>
      );
    };

    const renderOption = (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: {
        label?: string;
        value?: string | number;
      }
    ) => {
      switch (renderType) {
        case "renderExtra":
          return renderOptionExtra(props, option);
        case "renderSimple":
          return renderOptionSimple(props, option);
        default:
          return renderOptionDefault(props, option);
      }
    };

    return (
      <Autocomplete
        disabled={meta.submitting || disabled}
        options={options}
        loading={loading}
        loadingText={translate("loading")}
        id={id}
        defaultValue={defaultValue}
        renderOption={renderOption}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={filterOptions}
        getOptionLabel={(option: any) =>
          option && endAdornment ? option.value : option.label
        }
        onChange={(e: {}, value: any) =>
          input.onChange(value && value.value ? value.value : "")
        }
        inputValue={
          endAdornment || inputValue
            ? typeof input.value !== "string"
              ? JSON.stringify(input.value)
              : input.value
            : undefined
        }
        popupIcon={endAdornment ? null : <ExpandMoreOutlined />}
        onBlur={input.onBlur}
        noOptionsText={translate("noOptions", "Nėra tokio pasirinkimo")}
        disableClearable={!!endAdornment}
        ListboxComponent={ListboxComponent}
        ListboxProps={ListboxProps}
        renderInput={(params) => (
          <TextField
            {...params}
            {...input}
            {...restTextField}
            label={label}
            disabled={meta.submitting || disabled}
            error={isError}
            helperText={helperText}
            otherColor={otherColor}
            fullWidth={fullWidth}
            required={required}
            endAdornment={endAdornment}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            ref={ref}
          />
        )}
        classes={{
          root: cx(classes.root, {
            [classes.greyBorder]: otherColor === "grey",
          }),
        }}
        {...rest}
      />
    );
  }
);

export default React.memo(OutlinedAutocomplete);
