import React, { useState } from "react";

declare const Html5Qrcode: any;

function useQRCodeScan({
  qrcodeMountNodeID = "",
  getQrBoxDimension,
  cameraId,
}: {
  qrcodeMountNodeID: string | number;
  getQrBoxDimension?: () => number;
  cameraId?: string;
}) {
  const [decodedQRData, setDecodedQrData] = useState<{
    isScanning: boolean;
    isScanSuccess: boolean;
    isScanFailure: boolean;
    data: null | string;
    error: any;
  }>({
    isScanning: false,
    isScanSuccess: false,
    isScanFailure: false,
    data: null,
    error: "",
  });
  const html5QrCodeScannerRef = React.useRef<any>(null);

  // unmount logic
  React.useEffect(() => {
    return () => {
      if (html5QrCodeScannerRef.current) {
        html5QrCodeScannerRef.current?.stop()?.catch((err: any) => {
          console.log(err);
        });
      }
    };
  }, []);

  function stopQrCode() {
    if (html5QrCodeScannerRef.current) {
      html5QrCodeScannerRef.current?.stop()?.catch((err: any) => {
        console.log(err);
      });
    }
  }

  function startQrCode() {
    try {
      setDecodedQrData({
        ...decodedQRData,
        isScanning: true,
        data: null,
      });
      // eslint-disable-next-line
      const html5qrCodeScanner = new Html5Qrcode(qrcodeMountNodeID);

      html5QrCodeScannerRef.current = html5qrCodeScanner;

      let qrbox = 200;
      if (getQrBoxDimension) {
        qrbox = getQrBoxDimension();
      }

      html5qrCodeScanner
        .start(
          cameraId
            ? { deviceId: { exact: cameraId } }
            : { facingMode: "environment" },
          { fps: 500, qrbox, aspectRatio: 1 },
          (qrCodeMessage: string) => {
            // after successfull scanning
            setDecodedQrData({
              ...decodedQRData,
              isScanSuccess: true,
              isScanning: false,
              data: qrCodeMessage,
              error: "",
            });
          },
          (errorMessage: any) => {
            console.log(errorMessage);
          }
        )
        .catch((err: any) => {
          setDecodedQrData({
            ...decodedQRData,
            isScanSuccess: false,
            isScanning: false,
            isScanFailure: true,
            data: null,
            error: err || "QR Code parsing failed",
          });
        });
    } catch (e) {
      setDecodedQrData({
        ...decodedQRData,
        isScanSuccess: false,
        isScanning: false,
        isScanFailure: true,
        data: null,
        error: e || "QR Code parsing failed",
      });
    }
  }

  return {
    startQrCode,
    decodedQRData,
    stopQrCode,
  };
}

export default useQRCodeScan;
