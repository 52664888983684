import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BarChartOutlined,
  LocalOfferOutlined,
  ExitToAppOutlined,
  DashboardOutlined,
  PowerSettingsNewOutlined,
  SettingsOutlined,
  EventNoteOutlined,
  InfoOutlined,
  ApartmentOutlined,
} from "@mui/icons-material";
import { Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../auth/slices/authSlice";
import {
  userClientDb,
  userFullName,
  userId,
  uiLanguage,
} from "../../auth/selectors/userSelector";
import { IconField, Alert, NavigationBar } from "../../../components";
import { useOptionsListStyles } from "../css";
import { LogoHeader } from "../../../logo";
import {
  useLocalStorage,
  useAuthorizationError,
  useLoader,
} from "../../../hooks";
import { checkForCustomProcedures } from "../../api/parameters";
import { getErrorMessage } from "../../../components/form/formUtils";
import * as Routes from "../../../app/routes";

interface IListItems {
  title: string;
  route: string;
  icon: React.ReactNode;
  color?: "green" | "white" | undefined;
}

function OptionsList() {
  const items: IListItems[] = [];
  const { classes } = useOptionsListStyles();
  const [translate] = useTranslation();
  const [userDb, setUserDb] = useState(useSelector(userClientDb));
  const user = useSelector(userId);
  const userName = useSelector(userFullName);
  const locale = useSelector(uiLanguage);
  const [localStorage] = useLocalStorage("userDb");
  const dispatch = useDispatch();
  const activeLink =
    userDb && userDb.id
      ? { className: classes.link }
      : { className: classes.notAllowed };
  const [error, setError] = useState({ error: false, message: "", status: 0 });
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [, setIsCustomProc] = useLocalStorage("productCustomProc");
  const [logoutUser] = useAuthorizationError();
  const [, setLoader] = useLoader();

  items.push(
    {
      title: translate("sales", "Pardavimai"),
      route: Routes.ROUTE_SALES.path,
      icon: <BarChartOutlined fontSize="small" />,
    },
    {
      title: translate("purchases", "Pirkimai"),
      route: Routes.ROUTE_PURCHASES.path,
      icon: <LocalOfferOutlined fontSize="small" />,
    },
    {
      title: translate("insideAccounting", "Vidiniai judėjimai"),
      route: Routes.ROUTE_INTERNAL.path,
      icon: <ExitToAppOutlined fontSize="small" />,
    },
    {
      title: translate("caches", "Kaupikliai"),
      route: Routes.ROUTE_SCANNER.path,
      icon: <DashboardOutlined fontSize="small" />,
    },
    {
      title: translate("inventory", "Inventorizacija"),
      route: Routes.ROUTE_INVENTORY.path,
      icon: <EventNoteOutlined fontSize="small" />,
    },
    {
      title: translate("assembly", "Gamyba"),
      route: Routes.ROUTE_ASSEMBLY.path,
      icon: <ApartmentOutlined fontSize="small" />,
    },
    {
      title: translate("productInformation", "Prekės informacija"),
      route: Routes.ROUTE_PRODUCT_INFO.path,
      icon: <InfoOutlined fontSize="small" />,
    }
  );

  const clickAlert =
    userDb && userDb.id
      ? {}
      : {
          onClick: () =>
            setError({
              error: true,
              message: translate(
                "setDatabase",
                "Nustatymuose pasirinkite duomenų bazę su kuria dirbsite."
              ),
              status: 0,
            }),
        };

  const logUserOut = React.useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  React.useEffect(
    () => {
      if (!userDb && user) {
        const lastUserDb =
          localStorage && Array.isArray(localStorage)
            ? localStorage.filter((item: any) => item.userId === user)
            : [];

        if (lastUserDb.length > 0) {
          setUserDb(lastUserDb[0]);
        }
      }
    }, // eslint-disable-next-line
    []
  );

  const handleClose = () => {
    logoutUser(error.status);

    if (error.status !== 401)
      setError({ error: false, message: "", status: 0 });
  };

  React.useEffect(
    () => {
      if (userDb && userDb.id) {
        const paramsProc = {
          clientDbId: userDb.id,
          locale,
        };
        setLoader(true);
        checkForCustomProcedures(paramsProc)
          .then(({ data }) => {
            setLoader(false);
            if (data && data.length > 0) {
              const customProc = data.filter(
                (param: any) =>
                  param.procName.toUpperCase() ===
                  "RGI_MSCAN_GET_PRODUCT_INFO_CUSTOM"
              );

              if (
                customProc.length > 0 &&
                (customProc[0].isCustomProc === 1 ||
                  customProc[0].isCustomProc === "1")
              )
                setIsCustomProc(true);
              else setIsCustomProc(false);
            }
          })
          .catch((reason) => {
            setLoader(false);
            const errMessage = getErrorMessage(reason);
            setError({
              error: true,
              message: errMessage.message,
              status: errMessage.status,
            });
          });
      }
    }, // eslint-disable-next-line
    [userDb]
  );

  const handleCloseButton = () => {
    if (locationState.from !== Routes.ROUTE_LOGIN.path) {
      navigate(-1);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.grid}
      wrap="nowrap"
    >
      {error.error && (
        <Alert
          isOpen={error.error}
          message={error.message}
          iconColor="error"
          onClose={handleClose}
        />
      )}

      <Grid item>
        <NavigationBar
          moduleName={<LogoHeader width="100" />}
          closeButton={handleCloseButton}
        />

        {items.map((option) => (
          <div key={option.route} {...clickAlert}>
            <Link to={option.route} {...activeLink}>
              <IconField
                title={option.title}
                icon={option.icon}
                disabled={!(userDb && userDb.id)}
                greenText={option.route === locationState?.from}
              />
            </Link>
          </div>
        ))}
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>

      <Grid
        item
        justifyContent="flex-end"
        container
        direction="column"
        wrap="nowrap"
      >
        <Grid item>
          <Link
            to={Routes.ROUTE_SETTINGS.path}
            state={locationState}
            className={classes.link}
          >
            <IconField
              title={translate("settings", "Nustatymai")}
              icon={<SettingsOutlined fontSize="small" />}
              greenText={Routes.ROUTE_SETTINGS.path === locationState?.from}
            />
          </Link>
        </Grid>

        <Grid item>
          <IconField
            title={translate("logout", "Atsijungti")}
            icon={<PowerSettingsNewOutlined fontSize="small" />}
            onClick={logUserOut}
            iconOnRight={userName}
            greenText
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(OptionsList);
