import DatePicker from "@mui/lab/DesktopDatePicker";
import { TextField } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useOutlinedDatePickerStyles } from "./css";

interface IOutlinedDatePicker {
  name: string;
  clearLabel?: string;
  disabled?: boolean;
  validate?: (value: any) => any;
  format?: string;
  autoOk?: boolean;
  clearable?: boolean;
  label?: string;
  otherColor?: "white" | "grey";
  autoComplete?: "current-password" | "username" | "off";
}

const OutlinedDatePicker = React.forwardRef<any, IOutlinedDatePicker>(
  (props, ref) => {
    const [translate] = useTranslation();
    const { classes, cx } = useOutlinedDatePickerStyles();
    const {
      name,
      validate,
      disabled,
      clearLabel = translate("clear", "Išvalyti"),
      otherColor,
      autoComplete,
      ...rest
    } = props;

    return (
      <Field
        name={name}
        validate={validate}
        render={({
          meta: { submitting, error },
          input: { value, ...inputProps },
        }) => {
          const onChange = (date: any) => {
            try {
              if (date instanceof Date) {
                if (isNaN(date.valueOf())) {
                  // Commit null until the user enters the valid date
                  inputProps.onChange(null);
                } else {
                  //3 hours addition is needed because of timezone
                  //otherwise datepicker is subtracting 1 day when entering dates manually
                  inputProps.onChange(
                    new Date(date.getTime() + 3 * 60 * 60 * 1000)
                      .toISOString()
                      .substring(0, 10)
                  );
                }
              } else {
                inputProps.onChange(null);
              }
            } catch (err) {
              console.error(err);
              inputProps.onChange(null);
            }
          };

          const isDisabled = disabled || submitting;
          const isError = !!error;
          const helperText = isError ? error : null;

          return (
            <DatePicker
              {...inputProps}
              {...rest}
              mask="____-__-__"
              disabled={isDisabled}
              value={value ? new Date(value) : null}
              className={cx(classes.root, classes.greyBorder)}
              onChange={onChange}
              clearText={clearLabel}
              rightArrowButtonText={translate("nextMonth", "Kitas mėnuo")}
              leftArrowButtonText={translate(
                "previousMonth",
                "Ankstesnis mėnuo"
              )}
              maxDate={new Date("3999-01-01")}
              minDate={new Date("1900-01-01")}
              inputFormat="yyyy-MM-dd"
              disableCloseOnSelect={false}
              inputRef={ref}
              InputProps={{ classes: { root: classes.input } }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  error={isError}
                  helperText={helperText}
                  autoComplete={autoComplete}
                  InputLabelProps={{
                    className: cx(classes.label, {
                      [classes.greyLabel]: otherColor === "grey",
                    }),
                  }}
                />
              )}
            />
          );
        }}
      />
    );
  }
);

export default React.memo(OutlinedDatePicker);
