import React from "react";
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { useDialogStyles } from "./css";
import { CloseOutlined } from "@mui/icons-material";

interface IDialog {
  open: boolean;
  onClose: (event: {}) => void;
  onConfirm?: (event: {}) => void;
  children: React.ReactNode;
  title?: string;
  isConfirmationButton?: boolean;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  confirmButtonRef?: React.MutableRefObject<HTMLButtonElement | undefined>;
}

function Dialog(props: IDialog) {
  const {
    open,
    onClose,
    onConfirm,
    children,
    title,
    isConfirmationButton,
    maxWidth = "sm",
    confirmButtonRef,
  } = props;
  const [translate] = useTranslation();
  const { classes, cx } = useDialogStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.between(0, 400));

  return (
    <MuiDialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      classes={{ root: classes.contentRoot }}
    >
      <DialogTitle
        classes={{
          root: cx({
            [classes.dialogTitle]: !smallScreen,
            [classes.dialogTitleSmallScreen]: smallScreen,
          }),
        }}
      >
        <div className={classes.header}>
          <div className={classes.title}>{title}</div>
          <div>
            <IconButton onClick={onClose} size="large">
              <CloseOutlined className={classes.icon} />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      <DialogContent
        classes={{
          root: cx(classes.dialogContent, {
            [classes.dialogContentSmallScreen]: smallScreen,
          }),
        }}
      >
        {children}
      </DialogContent>

      <DialogActions>
        <Grid container direction="column" spacing={2}>
          {isConfirmationButton && (
            <Grid item>
              <Button
                onClick={onConfirm}
                fullWidth={true}
                disableElevation
                ref={confirmButtonRef}
              >
                {translate("continue", "Tęsti")}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </MuiDialog>
  );
}

export default Dialog;
