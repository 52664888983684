import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BaseLayout } from "../layouts";
import { useErrorPageStyles } from "./css";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Button } from "./index";
import * as routes from "../app/routes";

const ErrorPage = () => {
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const { classes } = useErrorPageStyles();

  const onClick = () => {
    navigate(routes.ROUTE_OPTIONS.path);
  };

  return (
    <BaseLayout>
      <div className={classes.root}>
        <ErrorOutlineOutlined className={classes.icon} fontSize="inherit" />
        <div>{translate("pageDoesNotExists")}</div>
        <Button color="secondary" onClick={onClick}>
          {translate("back")}
        </Button>
      </div>
    </BaseLayout>
  );
};

export default ErrorPage;
