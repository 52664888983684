import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const theme = createTheme({
  palette: {
    common: { black: "#0A0203", white: "#FFFFFF" },
    primary: { main: "#00b373", contrastText: "#FFFFFF" },
    secondary: { main: "#0b3f30", contrastText: "#FFFFFF" },
    error: { main: "#E63B70", contrastText: "#FFFFFF" },
    warning: { main: "#FDBF58", contrastText: "#FFFFFF" },
    grey: {
      A100: "#F3F3F5",
      A200: "rgba(255,255,255,0.5)",
      A400: "rgba(255,255,255,0.2)",
      A700: "rgba(10,2,3,0.5)",
    },
  },
  typography: {
    fontFamily: "SymphonieGrotesque-Regular",
    fontSize: 18,
    button: {
      fontSize: 18,
    },
    body2: {
      fontSize: 18,
    },
    body1: {
      fontSize: 18,
    },
    caption: {
      fontSize: 18,
    },
    h2: {
      fontSize: 20,
      fontFamily: "SymphonieGrotesque-Medium",
    },
  },
  spacing: (factor: number) => `${factor * 1}vh`,
});

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

interface IAppTheme {
  children?: React.ReactNode;
}

function AppTheme(props: IAppTheme) {
  return (
    <CacheProvider value={muiCache}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </CacheProvider>
  );
}

export default AppTheme;
