import React, { useState } from "react";
import { useQRCodeScan, useLocalStorage, usePreviousState } from "../hooks";
import { Alert, Menu, Fab } from "./index";
import { Card, CardActionArea, CardMedia } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userId } from "../modules/auth/selectors/userSelector";
import {
  setUserCameraUsage,
  setUserCameraId,
} from "../modules/auth/slices/userSlice";
import {
  isAlreadySet,
  otherUsersSettings,
} from "../modules/settings/components/utils";

interface ICamera2 {
  onUpdate: (result: any) => void;
}

function Camera2(props: ICamera2) {
  const { onUpdate } = props;
  const dispatch = useDispatch();

  const [openCamerasList, setOpenCamerasList] = useState(null);
  const [camerasList, setCamerasList] =
    useState<{ id: string; label: string }[]>();
  const [cameraId, setCameraId] = useState<string | undefined>();
  const previousCameraId = usePreviousState(cameraId);
  const user = useSelector(userId);

  const [camerIdLS, setCameraIdLS] = useLocalStorage("cameraId", [
    { userId: user, cameraId: "" },
  ]);
  const [readyToUse, setReadyToUse] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    error: false,
  });

  const { startQrCode, decodedQRData, stopQrCode } = useQRCodeScan({
    qrcodeMountNodeID: "qrcodemountnode",
    cameraId: cameraId,
  });

  React.useEffect(() => {
    if (decodedQRData && decodedQRData.error)
      setErrorMessage({ message: decodedQRData.error, error: true });
  }, [decodedQRData]);

  const handleCameraList = async (event: any) => {
    setOpenCamerasList(event.currentTarget);
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices
      .filter((device) => device.kind === "videoinput")
      .map((device) => {
        return { id: device.deviceId, label: device.label };
      });

    setCamerasList(cameras);
  };

  React.useEffect(
    () => {
      onUpdate(decodedQRData);
    }, // eslint-disable-next-line
    [decodedQRData]
  );

  React.useEffect(() => {
    //cameraId setting
    const isCameraId = isAlreadySet(camerIdLS, user);
    dispatch(
      setUserCameraUsage(isCameraId.length > 0 ? isCameraId[0].cameraId : "")
    );
    if (isCameraId.length > 0) setCameraId(isCameraId[0].cameraId);
  }, [dispatch, camerIdLS, user]);

  const switchCamera = async () => {
    try {
      await stopQrCode();
      await startQrCode();
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(
    () => {
      if (previousCameraId !== cameraId) switchCamera();
      setReadyToUse(true);
    }, // eslint-disable-next-line
    [cameraId, previousCameraId]
  );

  const handleMenuClose = () => setOpenCamerasList(null);

  const handleSelectedCamera = (index: number) => {
    if (camerasList && camerasList.length > 0 && camerasList[index]) {
      setCameraId(camerasList[index].id);
      dispatch(setUserCameraId(camerasList[index].id));

      const otherUsers = otherUsersSettings(camerIdLS, user);
      setCameraIdLS([
        ...otherUsers,
        { userId: user, cameraId: camerasList[index].id },
      ]);
    }

    setOpenCamerasList(null);
  };

  const handleCancelAlert = () => {
    setErrorMessage({ error: false, message: "" });
  };

  React.useEffect(
    () => {
      return stopQrCode();
    }, // eslint-disable-next-line
    []
  );

  return (
    <Card>
      {errorMessage.error && (
        <Alert
          message={errorMessage.message}
          isOpen={errorMessage.error}
          onClose={handleCancelAlert}
          iconColor="error"
        />
      )}

      {readyToUse && (
        <CardActionArea>
          <CardMedia>
            <div id="qrcodemountnode" />
            <Fab onClick={handleCameraList} />
          </CardMedia>
        </CardActionArea>
      )}
      {Boolean(openCamerasList) && (
        <Menu
          open={Boolean(openCamerasList)}
          anchorEl={openCamerasList}
          onClose={handleMenuClose}
          data={
            camerasList
              ? camerasList.map((device, index) => {
                  return {
                    name: device.label,
                    onClick: () => handleSelectedCamera(index),
                  };
                })
              : []
          }
        />
      )}
    </Card>
  );
}

export default Camera2;
