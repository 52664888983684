import { makeStyles } from "tss-react/mui";

const useDocumentDetailsStyles = makeStyles()((theme) => ({
  container: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  badge: {
    "& .MuiBadge-badge": {
      right: 10,
      backgroundColor: "#c964ff",
    },
  },
}));

export default useDocumentDetailsStyles;
