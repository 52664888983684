import * as routes from "../app/routes";

export const MODULE_SALES = "RO";
export const MODULE_PURCHASES = "PO";
export const MODULE_INTERNAL = "VD";
export const MODULE_SCANNER = "KP";
export const MODULE_INVENTORY = "IF";
export const MODULE_PRODUCT = "PR";
export const MODULE_ASSEMBLY = "KA";

export type moduleType = "RO" | "PO" | "VD" | "KP" | "IF" | "PR" | "KA";

export function modulePath(module: string) {
  let path = "/";
  switch (module) {
    case MODULE_SALES:
      path = routes.ROUTE_SALES_DETAILS.path;
      break;
    case MODULE_PURCHASES:
      path = routes.ROUTE_PURCHASES_DETAILS.path;
      break;
    case MODULE_INTERNAL:
      path = routes.ROUTE_INTERNAL_DETAILS.path;
      break;
    case MODULE_SCANNER:
      path = routes.ROUTE_SCANNER_DETAILS.path;
      break;
    case MODULE_INVENTORY:
      path = routes.ROUTE_INVENTORY_DETAILS.path;
      break;
    case MODULE_PRODUCT:
      path = routes.ROUTE_PRODUCT_INFO.path;
      break;
    case MODULE_ASSEMBLY:
      path = routes.ROUTE_OPTIONS.path;
      break;
    default:
      path = routes.ROUTE_ASSEMBLY_DETAILS.path;
  }

  return path;
}
