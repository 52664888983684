import React from "react";
import { useTranslation } from "react-i18next";
import { Drawer as MuiDrawer, Grid, Typography, Paper } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import Button from "./Button";
import { useAlertStyles } from "./css";

interface IAlert {
  isOpen: boolean;
  onClose: (event: {}) => void;
  onConfirm?: (event: {}) => void;
  iconColor?: "error" | "warning" | "success";
  isConfirmationButton?: boolean;
  message?: string;
}

function Alert(props: IAlert) {
  const {
    isOpen = false,
    onClose,
    message,
    isConfirmationButton,
    onConfirm,
    iconColor,
  } = props;
  const { classes, cx } = useAlertStyles();
  const [translate] = useTranslation();
  const messageArray = message ? message.split(/\n/) : [];

  React.useEffect(() => {
    if ("vibrate" in navigator) {
      // vibration API supported
      window.navigator.vibrate(300);
    }
  }, []);

  return (
    <MuiDrawer
      open={isOpen}
      anchor="bottom"
      onClose={onClose}
      elevation={0}
      variant="temporary"
      classes={{ root: classes.root }}
    >
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          justifyContent="space-around"
          direction="column"
          spacing={3}
        >
          <Grid item container justifyContent="center">
            <Grid item xs={6} container justifyContent="center">
              <ErrorOutlineOutlined
                fontSize="large"
                className={cx(classes.iconWarning, {
                  [classes.iconError]: iconColor === "error",
                  [classes.iconSuccess]: iconColor === "success",
                })}
              />
            </Grid>
          </Grid>

          <Grid item container justifyContent="center">
            <Typography variant="body1" className={classes.typography}>
              {messageArray}
            </Typography>
          </Grid>

          <Grid item container direction="column" spacing={2}>
            {isConfirmationButton && (
              <Grid item>
                <Button onClick={onConfirm} fullWidth disableElevation>
                  {translate("continue", "Tęsti")}
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button
                onClick={onClose}
                fullWidth
                otherColors="white"
                disableElevation
              >
                {translate("cancel", "Uždaryti")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MuiDrawer>
  );
}

export default Alert;
