import React, { useState } from "react";
import { IconButton, Popover, Typography } from "@mui/material";
import { useInfoButtonStyles } from "./css";
import { HelpOutlineOutlined } from "@mui/icons-material";

interface ICheckboxExampleState {
  disabled?: boolean;
  text: string;
}

function InfoButton(props: ICheckboxExampleState) {
  const { text, ...rest } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { classes } = useInfoButtonStyles();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event && event.currentTarget) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
        className={classes.root}
        {...rest}
      >
        <HelpOutlineOutlined fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.text}>{text}</Typography>
      </Popover>
    </div>
  );
}

export default InfoButton;
