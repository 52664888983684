import React from "react";
import { TextField as MuiTextField, InputAdornment } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useTextFieldStyles } from "./css";

export interface ITextFieldProps {
  name?: string;
  label?: string;
  disabled?: boolean;
  helperText?: string;
  onChange?: React.FormEventHandler<HTMLElement>;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  placeholder?: string;
  type?: string;
  value?: string | number;
  autoComplete?: "current-password" | "username" | "off";
  required?: boolean;
  margin?: "none" | "dense" | "normal" | undefined;
  inputMode?: "numeric" | "decimal";
  endAdornment?: JSX.Element;
  fullWidth?: boolean;
  select?: boolean;
  error?: boolean;
  children?: React.ReactNode;
  otherColor?: "white" | "grey";
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }
  ) => void;
  multiline?: boolean;
  maxLength?: number;
  InputProps?: {};
  inputProps?: {};
  InputLabelProps?: {};
}

const TextField = React.forwardRef<any, ITextFieldProps>((props, ref) => {
  const {
    disabled = false,
    helperText: userHelperText,
    endAdornment: icon,
    fullWidth = true,
    select,
    error,
    label,
    value,
    children,
    onChange,
    onFocus,
    type,
    inputMode,
    autoComplete,
    margin,
    otherColor,
    maxLength,
    InputProps,
    inputProps,
    InputLabelProps,
    ...rest
  } = props;
  const { classes, cx } = useTextFieldStyles();
  const endIcon = icon
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <div>{icon}</div>
          </InputAdornment>
        ),
      }
    : {};

  return (
    <MuiTextField
      disabled={disabled}
      error={error}
      helperText={userHelperText}
      fullWidth={fullWidth}
      classes={{
        root: cx(classes.root, {
          [classes.borders]: otherColor === "white",
          [classes.greyBorder]: otherColor === "grey",
        }),
      }}
      variant="outlined"
      size="small"
      select={select}
      label={label}
      value={value}
      inputRef={ref}
      onFocus={onFocus}
      InputLabelProps={{
        ...InputLabelProps,
        className: cx(classes.label, {
          [classes.greyLabel]: otherColor === "grey",
        }),
      }}
      InputProps={{
        ...InputProps,
        ...endIcon,
        classes: {
          root: cx(classes.input, {
            [classes.white]: otherColor === "white",
            [classes.greyBorder]: otherColor === "grey",
          }),
          input: cx({
            [classes.white]: otherColor === "white",
          }),
        },
      }}
      inputProps={{
        ...inputProps,
        inputMode: inputMode,
        maxLength: maxLength,
      }}
      onChange={onChange}
      type={type}
      autoComplete={autoComplete}
      margin={margin}
      SelectProps={{
        IconComponent: ExpandMoreOutlined,
      }}
      {...rest}
    >
      {children}
    </MuiTextField>
  );
});

export default React.memo(TextField);
