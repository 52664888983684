import React, { useState } from "react";
import { Dialog, Alert } from "../../../components";
import {
  getInventoryParams,
  createInventoryOperation,
} from "../../api/inventory";
import {
  uiLanguage,
  userClientDb,
  userDepartmentId,
} from "../../auth/selectors/userSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getErrorMessage,
  getWarningMessage,
} from "../../../components/form/formUtils";
import { Navigate } from "react-router-dom";
import * as routes from "../../../app/routes";
import { Grid } from "@mui/material";
import { Form } from "react-final-form";
import {
  OutlinedTextField,
  OutlinedAutocomplete,
} from "../../../components/form";

interface IInventoryFormCreate {
  open: boolean;
  onClose: () => void;
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

let id = "";

function InventoryFormCreate(props: IInventoryFormCreate) {
  const { open, onClose } = props;
  const [description, setDescription] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const [operationsList, setOperationsList] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const [redirect, setRedirect] = useState({ redirect: false, id: "" });
  const [chosenDescription, setChosenDescription] = useState<{
    value: number;
    label: string;
  }>();
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    error: false,
  });
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const departmentId = useSelector(userDepartmentId);
  const params = {
    clientDbId: userDb ? userDb.id : 0,
    locale: locale,
    departmentId: departmentId,
  };

  const initialValues = React.useMemo(
    () => ({
      inventoryListCode: "",
      description:
        chosenDescription && chosenDescription.value
          ? chosenDescription.value
          : "",
    }),
    [chosenDescription]
  );

  React.useEffect(
    () => {
      getInventoryParams(params)
        .then(({ data }) => {
          if (data && data.length > 0 && data[0].operationCode) {
            const opArray = data.map((operation: any) => {
              return {
                label:
                  translate("docNr", "Dok. Nr:") +
                  " " +
                  operation.documentNr +
                  ", " +
                  operation.docDate.slice(0, 10),
                value: operation.operationCode,
              };
            });

            opArray.sort(function (a: any, b: any) {
              return a.value - b.value;
            });

            setOperationsList(opArray);
          }

          if (data && data[0] && data[0].description) {
            const options = data[0].description;
            const descArray = options.split(/,\s*/).map((option: any) => {
              return { label: option, value: option };
            });
            setDescription(descArray);

            if (descArray.length >= 1) {
              setChosenDescription(descArray[0]);
            }
          }
        })
        .catch((reason) => {
          const message = getErrorMessage(reason);
          setShowAlert({ show: true, message: message.message, error: true });
        });
    },
    // eslint-disable-next-line
    []
  );

  const handleErrorClose = () => {
    setShowAlert({ show: false, message: "", error: false });
    onClose();
  };

  const handleConfirm = (values: any) => {
    const opParams = {
      ...params,
      ...values,
    };

    createInventoryOperation(opParams)
      .then(({ data, warnings }) => {
        if (warnings && warnings.length > 0) {
          id = warnings[0].operationCode;
          const message = getWarningMessage(warnings);
          setShowAlert({ show: true, message: message, error: false });
          return;
        }

        id = data[0].operationCode;
        setRedirect({ redirect: true, id: id });
      })
      .catch((reason) => {
        const message = getErrorMessage(reason);
        setShowAlert({ show: true, message: message.message, error: true });
      });
  };

  const handleWarningClose = React.useCallback(() => {
    setRedirect({ redirect: true, id: id });
  }, [setRedirect, id]);

  const showError = (
    <Alert
      isOpen={showAlert.show}
      message={showAlert.message}
      onClose={showAlert.error ? handleErrorClose : handleWarningClose}
      iconColor={showAlert.error ? "error" : "warning"}
    />
  );

  return (
    <div>
      {showAlert.show && showError}
      {redirect.redirect ? (
        <Navigate
          to={routes.ROUTE_INVENTORY_DETAILS.path + "/" + redirect.id}
        />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleConfirm}
          subscription={subscription}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} method="post">
              <Dialog
                open={open}
                onClose={onClose}
                title={translate("createOperation", "Nauja operacija")}
                isConfirmationButton
                onConfirm={handleSubmit}
              >
                <Grid container spacing={1} direction="column">
                  <Grid item xs={12}>
                    <OutlinedAutocomplete
                      name="inventoryListCode"
                      fullWidth
                      label={translate(
                        "inventoryListNo",
                        "Invent. prekių sąrašas"
                      )}
                      options={operationsList}
                      otherColor="grey"
                      renderType="renderExtra"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {description &&
                    description.length > 0 &&
                    chosenDescription ? (
                      <OutlinedAutocomplete
                        name="description"
                        fullWidth
                        label={translate("description", "Operacijos aprašymas")}
                        options={description}
                        otherColor="grey"
                        renderType="renderSimple"
                        defaultValue={chosenDescription}
                      />
                    ) : (
                      <OutlinedTextField
                        name="description"
                        fullWidth
                        label={translate("description", "Operacijos aprašymas")}
                        otherColor="grey"
                        autoComplete="off"
                      />
                    )}
                  </Grid>
                </Grid>
              </Dialog>
            </form>
          )}
        />
      )}
    </div>
  );
}

export default InventoryFormCreate;
