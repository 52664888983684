import { client } from "./utils";
import { formatAppListResponse } from "./utils/helpers";

export interface ICreateInternalOperation {
  clientDbId: number;
  locale: string;
  departmentCode1: string;
  departmentCode2: string;
  documentType: string;
  description: string;
}

export const createInternalOperation = (params: ICreateInternalOperation) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_CREATE_VD_DOC",
    data: {
      documentType: params.documentType,
      description: params.description,
      departmentCode1: params.departmentCode1,
      departmentCode2: params.departmentCode2,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};

export interface IGetInternalParams {
  clientDbId: number;
  locale: string;
  departmentId?: string;
}

export const getInternalParams = (params: IGetInternalParams) => {
  const query = {
    clientDbId: params.clientDbId,
    procedureName: "RGI_MSCAN_GET_INTERNAL_PARAMS",
    data: {
      departmentId: params.departmentId ? params.departmentId : undefined,
    },
  };

  return client
    .post("/client/application", query, {
      headers: {
        "accept-language": params.locale,
      },
    })
    .then(formatAppListResponse);
};
