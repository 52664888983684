import React from "react";

function useDoubleTap(callback: () => any) {
  /** callback ref Pattern **/
  const [elem, setElem] = React.useState<any>(null);
  const callbackRef: any = React.useCallback((node: any) => {
    setElem(node);
    callbackRef.current = node;
  }, []);

  /** useRef used for count **/
  const countRef = React.useRef(0);

  /** Refs for the timer **/
  const timerRef: any = React.useRef(null);

  /**Input callback Ref for callback passed **/
  const inputCallbackRef: any = React.useRef(null);

  React.useEffect(() => {
    inputCallbackRef.current = callback;
  });

  React.useEffect(() => {
    function handler() {
      const isDoubleClick = countRef.current + 1 === 2;
      const timerIsPresent = timerRef.current;
      if (timerIsPresent && isDoubleClick) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        countRef.current = 0;
        if (inputCallbackRef.current) {
          inputCallbackRef.current();
        }
      }
      if (!timerIsPresent) {
        countRef.current = countRef.current + 1;
        const timer = setTimeout(() => {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          countRef.current = 0;
        }, 200);
        timerRef.current = timer;
      }
    }
    if (elem) {
      elem.addEventListener("click", handler);
    }
    return () => {
      if (elem) {
        elem.removeEventListener("click", handler);
      }
    };
  }, [elem]);

  return [callbackRef, elem];
}

export default useDoubleTap;
