import React, { useState } from "react";
import { Arrow } from "../../logo";
import { useItemFieldStyles } from "./css";
import { CheckOutlined } from "@mui/icons-material";
import RowMenu from "../RowMenu";
import { useSelector } from "react-redux";
import { userExpandRows } from "../../modules/auth/selectors/userSelector";

interface ICellRenderer {
  complete?: boolean;
  disableMenu?: boolean;
  selectedRow?: boolean;
  data: {
    element1?: string;
    element2?: string;
    isFullyCollected?: number;
    quantityApp?: number;
    quantityDocument?: number;
    fraction?: number;
    rowNumber?: number;
    module?: string;
    operationCode?: string;
    operationType?: string;
    lot?: string;
    objectCode?: string;
    productCode?: string;
  };
}

function ItemField(props: ICellRenderer) {
  const { data, complete, disableMenu, selectedRow = false } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes, cx } = useItemFieldStyles();
  const expandRows = useSelector(userExpandRows);
  const onlyAppQty =
    data &&
    data.operationType &&
    (data.operationType.toUpperCase().indexOf("KAUP") > -1 ||
      data.operationType.toUpperCase().indexOf("INV") > -1);

  const docQty = React.useMemo(() => {
    let qty = data && data.quantityDocument ? data.quantityDocument : 0;
    if (
      data &&
      data.quantityDocument &&
      typeof data.quantityDocument === "string"
    )
      qty = parseFloat(data.quantityDocument);
    return qty;
  }, [data]);

  const appQty = React.useMemo(() => {
    let qty = data && data.quantityApp ? data.quantityApp : 0;
    if (data && data.quantityApp && typeof data.quantityApp === "string")
      qty = parseFloat(data.quantityApp);
    return qty;
  }, [data]);

  const fraction = React.useMemo(() => {
    let number = 0;

    if (data && data.fraction && data.fraction > 1 && data.fraction <= 10) {
      number = 1;
    } else if (
      data &&
      data.fraction &&
      data.fraction > 10 &&
      data.fraction <= 100
    ) {
      number = 2;
    } else if (
      data &&
      data.fraction &&
      data.fraction > 100 &&
      data.fraction <= 1000
    ) {
      number = 3;
    } else if (
      data &&
      data.fraction &&
      data.fraction > 1000 &&
      data.fraction <= 10000
    ) {
      number = 4;
    }

    return number;
  }, [data]);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {Boolean(anchorEl) && !disableMenu && (
        <RowMenu
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          detailedInfo={data}
          complete={complete}
        />
      )}

      {data && (
        <div
          className={cx(
            classes.container,
            {
              [classes.collectedRow]: "" + data.isFullyCollected === "1",
            },
            {
              [classes.selectedRow]: selectedRow,
            },
            {
              [classes.limitedHeight]: !expandRows,
            }
          )}
          onClick={handleOpenMenu}
        >
          <div
            className={cx(classes.left, {
              [classes.limitedHeight]: !expandRows,
            })}
          >
            <div className={cx({ [classes.noWrap]: !expandRows })}>
              {data && "" + data.isFullyCollected === "1" ? (
                <CheckOutlined
                  fontSize="inherit"
                  className={classes.collectedItem}
                />
              ) : (
                ""
              )}
              {data.element1}
            </div>
            <div
              className={cx(classes.bottomLeft, {
                [classes.noWrap]: !expandRows,
              })}
            >
              {data.element2}
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.columnsRight}>
              <div className={classes.lines}>{appQty.toFixed(fraction)}</div>
              {!onlyAppQty ? (
                <div className={classes.arrow}>
                  <Arrow width="7px" />
                </div>
              ) : (
                ""
              )}
              {!onlyAppQty ? (
                <div className={classes.lines}>{docQty.toFixed(fraction)}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ItemField;
