import { makeStyles } from "tss-react/mui";

const useOutlinedDatePickerStyles = makeStyles()((theme) => ({
  input: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "8.5px",
      // paddingBottom: "10.5px",
    },
  },
  root: {
    "& .MuiFormControl-root": {
      "& .MuiTextField-root": {
        width: "100%",
        border: "2px solid green !important",
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "#D5D7DB",
      transform: "translate(14px, 12px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey.A200,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiSelect-icon": {
      color: theme.palette.common.white,
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
  },
  greyBorder: {
    width: "100%",
    backgroundColor: "yellow",
    color: theme.palette.common.black,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D5D7DB",
      },
    },
    "& .MuiSelect-select": {
      "&:focus": {
        backgroundColor: theme.palette.common.white,
      },
    },
    "& .MuiSelect-icon": {
      color: theme.palette.common.black,
    },
  },
  label: {
    color: theme.palette.grey.A200,
  },
  greyLabel: {
    color: "#D5D7DB",
    // transform: "translate(14px, 12px) scale(1)",
    // "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //   transform: "translate(14px, -6px) scale(0.75)",
    // },
  },
}));

export default useOutlinedDatePickerStyles;
