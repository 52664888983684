import React, { useState } from "react";
import AssemblyList from "./components/AssemblyList";
import { BaseLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import * as routes from "../../app/routes";
import { Add } from "@mui/icons-material";
import AssemblyCreate from "./components/AssemblyCreate";

function Assembly() {
  const [translate] = useTranslation();
  const [createOp, setCreateOp] = useState(false);

  const handleClick = () => {
    setCreateOp(true);
  };

  const handleDialogClosing = () => {
    setCreateOp(false);
  };

  return (
    <BaseLayout
      moduleName={translate("assembly", "Gamyba")}
      modulePath={routes.ROUTE_ASSEMBLY.path}
      isDocumentList
      button={{
        btnIcon: <Add />,
        btnText: translate("new", "Nauja"),
        btnOnClick: handleClick,
      }}
    >
      {createOp && (
        <AssemblyCreate open={createOp} onClose={handleDialogClosing} />
      )}
      <AssemblyList />
    </BaseLayout>
  );
}

export default Assembly;
