import { makeStyles } from "tss-react/mui";

const useScannerFormStyles = makeStyles()((theme) => ({
  button: {
    width: "95%",
    margin: `${theme.spacing(2)} auto`,
  },
  container: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default useScannerFormStyles;
