import { makeStyles } from "tss-react/mui";

const useButtonStyles = makeStyles()((theme) => ({
  root: {
    fontFamily: "SymphonieGrotesque-Medium",
    textAlign: "start",
    textTransform: "none",
  },
  white: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #D5D7DB",
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  display: {
    display: "none",
  },
}));

export default useButtonStyles;
