import { makeStyles } from "tss-react/mui";

const useDrawerStyles = makeStyles()((theme) => ({
  container: {
    margin: "auto",
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: `${theme.spacing(1)} 0`,
    color: theme.palette.common.black,
  },
  root: {
    zIndex: 1400,
  },
  left: {
    fontWeight: "bold",
    width: "50%",
  },
  right: {
    color: theme.palette.grey.A700,
    width: "50%",
  },
  words: {
    overflowWrap: "break-word",
    width: "98%",
  },
  paper: {
    height: "100%",
  },
  closeButton: {
    textAlign: "right",
  },
  icon: {
    color: theme.palette.common.black,
  },
}));

export default useDrawerStyles;
