import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Form } from "react-final-form";
import { Dialog, Alert } from "../../../components";
import { updateProductQty } from "../../api/assembly";
import {
  uiLanguage,
  userClientDb,
  refreshData,
} from "../../auth/selectors/userSelector";
import { getErrorMessage } from "../../../components/form/formUtils";
import { OutlinedTextField, OutlinedCheckbox } from "../../../components/form";
import { useLoader } from "../../../hooks";
import { isNumber } from "../../../utils/validate";
import { setUserRefreshData } from "../../auth/slices/userSlice";

interface IAssemblyEditQuantityForm {
  open: boolean;
  onClose: (event?: Record<string, unknown>) => void;
  initialValues: {
    productQuantity: number;
    editComponents: boolean;
    operationCode: string;
  };
}

const subscription = {
  submitError: true,
  submitting: true,
  errors: true,
};

function AssemblyEditQuantityForm(props: IAssemblyEditQuantityForm) {
  const { open, onClose, initialValues } = props;
  const [showAlert, setShowAlert] = useState({ show: false, message: "" });
  const [translate] = useTranslation();
  const locale = useSelector(uiLanguage);
  const userDb = useSelector(userClientDb);
  const [, setLoader] = useLoader();
  const quantityRef = React.useRef<HTMLInputElement>();
  const editCompRef = React.useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const refresh = useSelector(refreshData);

  const params = {
    clientDbId: userDb ? userDb.id : 0,
    locale,
    operationCode: initialValues.operationCode,
  };

  React.useEffect(
    () => {
      if (quantityRef.current) quantityRef.current.focus();
    }, // eslint-disable-next-line
    [quantityRef]
  );

  const handleErrorClose = () => {
    setShowAlert({ show: false, message: "" });
  };

  const onSubmit = (values: any) => {
    setLoader(true);
    const opParams = {
      ...params,
      productQuantity: values.productQuantity,
      editComponents: values.editComponents,
    };

    updateProductQty(opParams)
      .then(() => {
        setLoader(false);
        dispatch(setUserRefreshData(!refresh));
        onClose();
      })
      .catch((reason) => {
        setLoader(false);
        const message = getErrorMessage(reason);
        setShowAlert({ show: true, message: message.message });
      });
  };

  const showError = (
    <Alert
      isOpen={showAlert.show}
      message={showAlert.message}
      onClose={handleErrorClose}
      iconColor="error"
    />
  );

  return (
    <div>
      {showAlert.show && showError}
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={subscription}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} method="post">
            <Dialog
              open={open}
              onClose={onClose}
              title={translate(
                "productAmountExchange",
                "Gaminio kiekio keitimas"
              )}
              isConfirmationButton
              onConfirm={handleSubmit}
            >
              <Grid container spacing={1} direction="column">
                <Grid item xs={12}>
                  <OutlinedTextField
                    name="productQuantity"
                    fullWidth
                    validate={isNumber}
                    label={translate("productquantity", "Gaminio kiekis")}
                    otherColor="grey"
                    autoComplete="off"
                    inputMode="decimal"
                    ref={quantityRef}
                  />
                </Grid>

                <Grid item xs={12}>
                  <OutlinedCheckbox
                    name="editComponents"
                    label={translate(
                      "recalculateComponents",
                      "Perskaičiuoti komponentes"
                    )}
                    labelColor="black"
                    ref={editCompRef}
                  />
                </Grid>
              </Grid>
            </Dialog>
          </form>
        )}
      />
    </div>
  );
}

export default AssemblyEditQuantityForm;
