import React from "react";
import { isDevEnv } from "../utils/env";
import { useTranslation } from "react-i18next";

interface IErrorFallback {
  error?: Error | null;
  errorInfo?: { componentStack: string } | null;
}

const ErrorFallback = (props: IErrorFallback) => {
  const { error, errorInfo } = props;
  const [translate] = useTranslation();
  if (isDevEnv()) {
    console.error(error); // eslint-disable-line no-console

    return (
      <div>
        <h1>{translate("unexpectedError", "Įvyko nenumatyta klaida...")}</h1>
        <pre>{errorInfo ? errorInfo.componentStack : ""}</pre>
      </div>
    );
  }

  return (
    <div>
      <h1>{translate("unexpectedError", "Įvyko nenumatyta klaida...")}</h1>
    </div>
  );
};

export default ErrorFallback;
